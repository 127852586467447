<template>
  <div>
    <headers></headers>
    <div class="bmbg">
      <div class="main1200 shadow-warp radius padding" style="">
        <div class="text-bold text-sl margin-xl">报名信息详情</div>
        <el-form
          :model="ruleForm"
          ref="ruleForm"
          label-width="145.59px"
          label-position="left"
          class="demo-ruleForm"
          style="background-color: #fbfbfd"
        >
          <el-col :span="11" style="margin-left: 15px; margin-right: 15px">
            <el-form-item label="姓名">
              <span>{{ ruleForm.truename }}</span>
            </el-form-item>
          </el-col>
          <el-col :span="11" style="margin-left: 15px; margin-right: 15px">
            <el-form-item label="身份证号">
              <span>{{ ruleForm.sfzhao }}</span>
            </el-form-item>
          </el-col>
          <el-col :span="11" style="margin-left: 15px; margin-right: 15px">
            <el-form-item label="性别">
              <span>{{ ruleForm.sex }}</span>
            </el-form-item>
          </el-col>
          <el-col :span="11" style="margin-left: 15px; margin-right: 15px">
            <el-form-item label="所在院校">
              <span>{{ ruleForm.szyxiao }}</span>
            </el-form-item>
          </el-col>
          <el-col :span="11" style="margin-left: 15px; margin-right: 15px">
            <el-form-item label="所在院系">
              <span>{{ ruleForm.szyxi }}</span>
            </el-form-item>
          </el-col>
          <el-col :span="11" style="margin-left: 15px; margin-right: 15px">
            <el-form-item label="专业名称">
              <span>{{ ruleForm.zymcheng }}</span>
            </el-form-item>
          </el-col>
          <el-col :span="11" style="margin-left: 15px; margin-right: 15px">
            <el-form-item label="学历">
              <span>{{ ruleForm.xueli }}</span>
            </el-form-item>
          </el-col>
          <el-col :span="11" style="margin-left: 15px; margin-right: 15px">
            <el-form-item label="手机号">
              <span>{{ ruleForm.phone }}</span>
            </el-form-item>
          </el-col>
          <el-col :span="11" style="margin-left: 15px; margin-right: 15px">
            <el-form-item label="电子邮箱">
              <span>{{ ruleForm.email }}</span>
            </el-form-item>
          </el-col>
          <el-col :span="11" style="margin-left: 15px; margin-right: 15px">
            <el-form-item label="学校所在地">
              <span>{{ ruleForm.szcity }}</span>
            </el-form-item>
          </el-col>
          <el-col :span="22" style="margin-left: 15px; margin-right: 15px">
            <el-form-item label="创作村庄">
              <span
                >{{ ruleForm.city }}{{ ruleForm.town
                }}{{ ruleForm.village }}</span
              >
            </el-form-item>
          </el-col>
          <el-col :span="11" style="margin-left: 15px; margin-right: 15px">
            <el-form-item label="作品形式">
              <span>{{ ruleForm.zpxshi }}</span>
            </el-form-item>
          </el-col>
          <el-col :span="11" style="margin-left: 15px; margin-right: 15px">
            <el-form-item label="作品类型">
              <span>{{ ruleForm.zplxing }}</span>
            </el-form-item>
          </el-col>
          <el-col :span="11" style="margin-left: 15px; margin-right: 15px">
            <el-form-item label="指导老师姓名">
              <span>{{ ruleForm.zdlshi }}</span>
            </el-form-item>
          </el-col>
          <el-col :span="11" style="margin-left: 15px; margin-right: 15px">
            <el-form-item label="作品名称">
              <span>{{ ruleForm.zpmcheng }}</span>
            </el-form-item>
          </el-col>
          <el-col :span="11" style="margin-left: 15px; margin-right: 15px">
            <el-form-item label="作品简介">
              <span>{{ ruleForm.zuopingjianjie }}</span>
            </el-form-item>
          </el-col>
          <el-col :span="20" style="margin-left: 15px; margin-right: 15px">
            <el-form-item
              label="作品电子版上传"
              label-width="146px"
              prop="name"
            >
              <!-- <el-upload
                id="photo1"
                action="http://ycny.njbtsiem.com/dqhxc/api/bm/uploadTemp"
                list-type="picture-card"
                :limit="3"
                style="
                  width: 283.19px;
                  float: left;
                  margin: 0 15px;
                  display: inline-block;
                "
              >
                <i class="el-icon-plus"></i>
              </el-upload> -->
              <div
                class="privilege-item"
                v-for="(item, index) in dzblist"
                :key="index"
              >
                <el-image
                  style="height: 176px; border: none"
                  :src="getImgSrc(item)"
                >
                  <div slot="placeholder" class="image-slot">
                    加载中<span class="dot">...</span>
                  </div>
                </el-image>
              </div>
            </el-form-item>
          </el-col>
          <el-col :span="20" style="margin-left: 15px; margin-right: 15px">
            <el-form-item
              label="作者描绘场景或人物实景照片上传"
              prop="name"
              label-width="146px"
            >
              <div
                class="privilege-item"
                v-for="(item, index) in zpcjinglist"
                :key="index"
              >
                <el-image
                  style="height: 176px; border: none"
                  :src="getImgSrc(item)"
                >
                  <div slot="placeholder" class="image-slot">
                    加载中<span class="dot">...</span>
                  </div>
                </el-image>
              </div>
            </el-form-item>
          </el-col>
          <el-col :span="20" style="margin-left: 15px; margin-right: 15px">
            <el-form-item
              id="photo7"
              label="作者在村庄现场作画的佐证照片上传"
              prop="name"
              label-width="146px"
            >
              <div
                class="privilege-item"
                v-for="(item, index) in xczhualist"
                :key="index"
              >
                <el-image
                  style="height: 176px; border: none"
                  :src="getImgSrc(item)"
                >
                  <div slot="placeholder" class="image-slot">
                    加载中<span class="dot">...</span>
                  </div>
                </el-image>
              </div>
            </el-form-item>
          </el-col>
          <el-col :span="20" style="margin-left: 15px; margin-right: 15px">
            <el-form-item
              label="指导老师指导作者创作照片上传"
              prop="name"
              label-width="146px"
            >
              <div
                class="privilege-item"
                v-for="(item, index) in lszdaolist"
                :key="index"
              >
                <el-image
                  style="height: 176px; border: none"
                  :src="getImgSrc(item)"
                >
                  <div slot="placeholder" class="image-slot">
                    加载中<span class="dot">...</span>
                  </div>
                </el-image>
              </div>
            </el-form-item>
          </el-col>
          <el-col :span="20" style="margin-left: 15px; margin-right: 15px">
            <el-form-item label="作品送选单位" prop="xinbie">
              <span>{{ ruleForm.danwei }}</span>
            </el-form-item>
          </el-col>
          <el-col :span="20" style="margin-left: 15px; margin-right: 15px">
            <el-form-item label="承诺书" prop="name">
              本人承诺参加第三届“丹青妙笔绘田园乡村”活动的绘画作品为本人原创，不存在抄袭或他人执笔等虚假行为；本人填写的各项报名信息全部真实有效，并严格遵守活动各项规则要求。如因本人提供虚假信息或违反活动规则等，本人愿承担一切责任及后果。
            </el-form-item>
          </el-col>
          <el-col :span="20" style="margin-left: 15px; margin-right: 15px">
            <el-form-item label-width="0px">
              <div class="row bmrow">
                <div class="col-md-12">
                  <div class="padding-sm">
                    <div class="qqm">承诺人签名</div>
                    <div>
                      <el-image
                        style="
                          height: 176px;
                          border: none;
                          margin-left: 145.59px;
                        "
                        :src="qianmingImg"
                      >
                        <div slot="placeholder" class="image-slot">
                          加载中<span class="dot">...</span>
                        </div>
                      </el-image>
                    </div>
                    <input type="hidden" id="id" name="id" value="" />
                  </div>
                </div>
              </div>
            </el-form-item>
          </el-col>
          <el-col :span="20" style="margin-left: 15px; margin-right: 15px">
            <el-form-item label-width="0px">
              <div class="col-md-6">
                <div class="layui-row">
                  <input
                    type="checkbox"
                    name="xieyi"
                    title=""
                    lay-skin="primary"
                    value="1"
                    checked=""
                    disabled="disabled"
                  />
                  <div
                    class="
                      layui-unselect layui-form-checkbox layui-form-checked
                    "
                    lay-skin="primary"
                  >
                    <i class="layui-icon"></i>
                  </div>
                  同意活动
                  <span class="text-blue" onclick="window.parent.kanxieyi();"
                    >《隐私政策》</span
                  >
                </div>
              </div>
            </el-form-item>
          </el-col>
          <el-col :span="20" style="margin-left: 15px; margin-right: 15px">
            <el-form-item label-width="0px">
              <el-button type="primary">返回</el-button>
            </el-form-item>
          </el-col>
        </el-form>
      </div>
    </div>
    <footers></footers>
    <div
      id="modal1653222159963"
      class="modal fade in"
      role="dialog"
      aria-labelledby="modalLabel"
      style="z-index: 2147483647; display: block; padding-right: 17px"
      v-show="deng"
    >
      <div class="modal-dialog" style="width: 200px">
        <div class="modal-content" style="border-radius: 20px">
          <div class="modal-header" style="display: none">
            <button type="button" class="close" data-dismiss="modal">
              <span aria-hidden="true">×</span
              ><span class="sr-only">Close</span>
            </button>
            <h4 class="modal-title" id="modalLabel">提示</h4>
          </div>
          <div class="modal-body">
            <div class="text-xxl text-center margin-top">
              <img src="@/assets/img/tip.jpg" class="tshi" />
            </div>
            <p
              class="text-xl text-center margin-top"
              style="color: #000000; text-align: center; text-indent: 0px"
              v-text="msgs"
            ></p>
            <div class="text-xl text-center margin-top margin-bottom">
              <button
                class="cu-btn round bg-gradual-green btn ok"
                data-dismiss="modal"
                style="
                  background-image: linear-gradient(45deg, #72b588, #6e9c7d);
                  border-radius: 16px;
                "
                @click="masks"
              >
                我知道了
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-backdrop fade in" v-show="deng"></div>
  </div>
</template>
<script>
import headers from "@/components/headers.vue";
import footers from "@/components/footers.vue";
import RequestApi from "@/api";

export default {
  data() {
    return {
      deng: false,
      msgs: "",
      masks: "",
      ruleForm: {
        id: "",
        city: "",
        creatime: "",
        danwei: "",
        dzban: "",
        email: "",
        lszdao: "",
        phone: "",
        qianming: "",
        sex: "",
        sfzhao: "",
        szcity: "",
        szyxi: "",
        szyxiao: "",
        tijiao: "",
        town: "",
        truename: "",
        updatime: "",
        user: "",
        xczhua: "",
        xieyi: "",
        xueli: "",
        zdlshi: "",
        zpcjing: "",
        zpjjie: "",
        zpmcheng: "",
        zpxshi: "",
        zymcheng: "",
      },
      dzblist: [],
      zpcjinglist: [],
      xczhualist: [],
      lszdaolist: [],
      qianmingImg: "",
    };
  },
  components: {
    headers,
    footers,
  },
  created() {
    this.getBaoMingDetailInfo();
  },
  mounted() {},
  methods: {
    getImgSrc(url) {
      return  RequestApi.StaticPath + url;
    },
    getBaoMingDetailInfo() {
      let that = this;
        this.$axios({
          url:"bm/getBaoMingDetailInfo",
          method:"get",
          params: {
            data: { id: sessionStorage.getItem("id") },
          },
        })
        .then((res) => {
          if (res.data.code == "200") {
            that.ruleForm = {
              city: res.data.data.city,
              creatime: res.data.data.creatime,
              danwei: res.data.data.danwei,
              dzban: res.data.data.dzban,
              email: res.data.data.email,
              id: res.data.data.id,
              lszdao: res.data.data.lszdao,
              phone: res.data.data.phone,
              qianming: res.data.data.qianming,
              sex: res.data.data.sex,
              sfzhao: res.data.data.sfzhao,
              szcity: res.data.data.szcity,
              szyxi: res.data.data.szyxi,
              szyxiao: res.data.data.szyxiao,
              tijiao: res.data.data.tijiao,
              town: res.data.data.town,
              truename: res.data.data.truename,
              updatime: res.data.data.updatime,
              user: res.data.data.user,
              xczhua: res.data.data.xczhua,
              xieyi: res.data.data.xieyi,
              xueli: res.data.data.xueli,
              zdlshi: res.data.data.zdlshi,
              zpcjing: res.data.data.zpcjing,
              zpjjie: res.data.data.zpjjie,
              zpmcheng: res.data.data.zpmcheng,
              zpxshi: res.data.data.zpxshi,
              zymcheng: res.data.data.zymcheng,
            };

            this.dzblist = res.data.data.dzban.split(",");
            this.zpcjinglist = res.data.data.zpcjing.split(",");
            this.xczhualist = res.data.data.xczhua.split(",");
            this.lszdaolist = res.data.data.lszdao.split(",");
            this.qianmingImg = res.data.data.qianming;
            this.$forceUpdate();
          }
        });
    },
  },
};
</script>
<style lang="scss" scoped>
/* .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}

.avatar-uploader .el-upload:hover {
    border-color: #409EFF;
}

.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
}

.avatar {
    width: 178px;
    height: 178px;
    display: block;
} */
/* .main::v-deep .el-table__row--striped .el-table__cell {
  background-color: #ebf2fa !important;
} */
.page {
  width: 100%;

  .content {
    width: 100%;
    height: 500px;
    background: url() no-repeat;
    background-size: 100% 100%;
    background-position: center center;

    .sign-wrap {
      width: 100%;
      height: 100%;
    }
  }

  .con-btn {
    width: 100%;
    display: flex;
    align-content: center;
    justify-content: space-between;
    opacity: 0.75;

    span {
      font-size: 0.14rem;
      width: 100%;
      height: 0.48rem;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .staging-btn {
      color: #4154ff;
      background: #fff;
    }

    .submit-btn {
      color: #fff;
      background: #4154ff;
    }
  }
}

.main1200::v-deep .el-upload__input {
  display: none;
}

.main1200::v-deep .el-upload--picture-card {
  width: 283.19px;
  height: 176px;
  margin: 0 15px;
  line-height: 176px;
}

.main1200::v-deep .el-form-item__content {
  width: 944px;
}

.layui-unselect {
  display: none;
}
</style>
