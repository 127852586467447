<template>
    <div class="app">
        <el-form ref="form" :model="form">
            <el-upload :class="{ hide: hideUploadEdit }" :action="upLoadUrl" list-type="picture-card"
                :on-preview="handlePicPreview" :on-remove="handlePicRemove" :on-success="handlePicSuccess"
                :on-change="handlePicChange" :file-list="fileList" :limit="1">
                <i class="el-icon-plus"></i>
            </el-upload>
            <el-dialog :visible.sync="dialogVisible">
                <img width="100%" :src="dialogImageUrl" alt="">
            </el-dialog>

        </el-form>
    </div>
</template>
<script>
export default {
    data() {
        return {
            fileList: [],
            dialogImageUrl: '',
            dialogVisible: false,
            hideUploadEdit: false, // 是否隐藏上传按钮
        }
    },
    methods: {
        handlePicRemove(file, fileList) {
            // 大于1张隐藏
            this.hideUploadEdit = fileList.length >= 1
        },
        handlePicChange(file, fileList) {
            // 大于1张隐藏
            this.hideUploadEdit = fileList.length >= 1
        },
        handlePicPreview(file) {
            this.dialogImageUrl = file.url;
            this.dialogVisible = true;
        },
        handlePicSuccess(response, file, fileList) {
            console.log("上传成功", response, file, fileList);
            console.log(response)
            this.zhengmianSrc = "http://file.rposea.com" + response.data.FILE_PATH
            console.log(this.zhengmianSrc)
        }

    }
}
</script>
<style>
.hide .el-upload--picture-card {
    display: none;
}
</style>