<template>
  <div>
    <div class="allbg">
      <div class="h100"></div>
      <div class="kuang1 shadow-warp bg-white overflow padding-xl">
        <div class="k1title text-right margin-right margin-top">
          <router-link to="/src/views/index.vue">
            <i class="el-icon-close"></i>
          </router-link>
        </div>
        <div class="text-sl text-bold">第三届</div>
        <div class="text-sl text-bold">丹青妙笔绘田园乡村</div>
        <div
          class="padding flex justify-around margin-xl text-xxl ltitle margin-bottom-xs"
        >
          <div id="mmdldiv" :class="{ active: false }" class="">密码登录</div>
        </div>
        <div class="padding-xl padding-top-0">
          <form
            id="register_form"
            class="login-form"
            method="post"
            name="register_form"
            role="form"
          >
            <div class="input-group-lg">
              <input
                id="phone"
                v-model="cellPhone"
                class="form-control radius"
                name="phone"
                placeholder="请输入用户名"
                required
                type="text"
                @blur="models"
              />
            </div>
            <div id="mima" class="input-group-lg margin-top">
              <input
                id="pass"
                v-model="password"
                class="form-control radius"
                name="pass"
                placeholder="请输入密码"
                required
                type="password"
                @blur="inpt"
              />
            </div>
            <!--            <div-->
            <!--                v-else-->
            <!--                id="yzm"-->
            <!--                class="input-group-lg margin-top"-->
            <!--                style="position: relative"-->
            <!--            >-->
            <!--              <div id="" style="position: absolute; right: 20px; top: 11px">-->
            <!--                <input-->
            <!--                    v-if="num!=60"-->
            <!--                    :value="num"-->
            <!--                    class="cu-btn sm bg-grey radius"-->
            <!--                    type="button"-->
            <!--                />-->
            <!--                <input-->
            <!--                    v-if="num==60"-->
            <!--                    class="cu-btn sm bg-grey radius"-->
            <!--                    type="button"-->
            <!--                    value="获取验证码"-->
            <!--                    @click="goes"-->
            <!--                />-->
            <!--              </div>-->
            <!--              <input-->
            <!--                  id="vercode"-->
            <!--                  v-model="yzm"-->
            <!--                  class="form-control radius"-->
            <!--                  maxlength="4"-->
            <!--                  name="vercode"-->
            <!--                  placeholder="请输入手机验证码"-->
            <!--                  required-->
            <!--                  type="text"-->
            <!--                  @blur="yzms"-->
            <!--              />-->
            <!--            </div>-->
            <div class="text-gray margin-top text-right" style="float: right">
              还没有帐号？
              <router-link class="text-black" to="/src/views/register.vue"
                >快速注册
              </router-link>
            </div>
            <div class="text-gray margin-top text-left">
              <router-link class="text-black" to="/src/views/forget_pwd.vue"
                >忘记密码
              </router-link>
            </div>
            <div style="height: 40px"></div>
            <div class="margin-top flex">
              <button
                id="login_btn"
                class="cu-btn bg-grey round lg flex-sub padding"
                type="button"
                @click="go"
              >
                登录
              </button>
            </div>
            <div style="height: 40px"></div>
          </form>

          <div class="solid-bottom"></div>

          <!-- <div class="text-left margin-top">第三方帐号登录</div>
          <div style="color: #04c904">
            <div
              class="cuIcon-weixin margin-top"
              style="font-size: 40px"
              @click="vxclick"
            ></div>
          </div> -->
        </div>
      </div>
    </div>
    <div
      v-show="deng"
      id="modal1653222159963"
      aria-labelledby="modalLabel"
      class="modal fade in"
      role="dialog"
      style="z-index: 2147483647; display: block; padding-right: 17px"
    >
      <div class="modal-dialog" style="width: 200px">
        <div class="modal-content" style="border-radius: 20px">
          <div class="modal-header" style="display: none">
            <button class="close" data-dismiss="modal" type="button">
              <span aria-hidden="true">×</span
              ><span class="sr-only">Close</span>
            </button>
            <h4 id="modalLabel" class="modal-title">提示</h4>
          </div>
          <div class="modal-body">
            <div class="text-xxl text-center margin-top">
              <img class="tshi" src="@/assets/img/tip.jpg" />
            </div>
            <p
              class="text-xl text-center margin-top"
              style="color: #000000; text-align: center; text-indent: 0px"
              v-text="msgs"
            ></p>
            <div class="text-xl text-center margin-top margin-bottom">
              <button
                class="cu-btn round bg-gradual-green btn ok"
                data-dismiss="modal"
                style="
                  background-image: linear-gradient(45deg, #72b588, #6e9c7d);
                  border-radius: 16px;
                "
                @click="masks"
              >
                我知道了
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      :visible.sync="centerDialogVisible"
      center
      title="微信扫一扫"
      top="20%"
      width="30%"
    >
      <img
        alt=""
        src="@/assets/img/appma.jpg"
        style="margin-top: 30px; margin-left: -129px; left: 50%"
      />

      <span slot="footer" class="dialog-footer"> </span>
    </el-dialog>
  </div>
</template>
<script>
import { setCookie } from "./utils";

export default {
  data() {
    return {
      centerDialogVisible: false,
      cellPhone: "",
      password: "",
      newPassword: "",
      code: "",
      yzm: "",
      yzm1: "11",
      // 验证
      cellPhone1: "",
      password1: "",
      newPassword1: "",
      code1: "",
      msgs: "",
      deng: false,
      dengs: false,
      flag: false,
      flags: false,
      time: 60,
      num: 60,
      FullScreen: false,
    };
  },
  mounted() {},
  methods: {
    enlarge() {
      // let element = document.documentElement;//全屏放大
      let element = document.getElementById("layui-layer1"); //需要全屏容器的id
      // 浏览器兼容
      if (this.FullScreen) {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen();
        }
      } else {
        if (element.requestFullscreen) {
          element.requestFullscreen();
        } else if (element.webkitRequestFullScreen) {
          element.webkitRequestFullScreen();
        } else if (element.mozRequestFullScreen) {
          element.mozRequestFullScreen();
        } else if (element.msRequestFullscreen) {
          element.msRequestFullscreen();
        }
      }
      this.FullScreen = !this.FullScreen;
    },
    vxclick() {
      this.dengs = true;
      this.flags = true;
    },
    vxinclick() {
      this.dengs = false;
      this.flags = false;
    },
    vxjian() {
      this.dengs = false;
      this.flags = false;
    },
    mclick() {
      this.dengs = false;
      this.flags = false;
    },
    masks() {
      this.deng = false;
    },
    yzms() {
      console.log(this.yzm);
    },
    models() {
      var namess =
        /^((13[0-9])|(14[0-9])|(15([0-9]))|(16([0-9]))|(17([0-9]))|(18[0-9])|(19[0-9]))\d{8}$/;
      if (this.cellPhone == "") {
        this.msgs = "请填写手机号";
      } else if (namess.test(this.cellPhone)) {
        this.cellPhone1 = "手机号正确";
      } else {
        this.msgs = "手机格式不正确";
      }
    },
    inpt() {
      var namev = "";
      if (this.password == "") {
        this.msgs = "密码不能为空";
      } else {
        this.password1 = "密码正确";
      }
    },

    tab() {
      this.flag = false;
    },
    tabs() {
      this.flag = true;
    },
    masks() {
      this.deng = false;
    },
    go() {
      if (this.cellPhone1 == "") {
        this.msgs = "手机号不能为空";
        this.deng = true;
        return;
      }

      if (this.cellPhone1 == "手机号正确" && this.password1 == "密码正确") {
        if (this.password1 == "") {
          this.msgs = "密码不能为空";
          this.deng = true;
          return;
        }
        let loginParam = {
          userPhone: this.cellPhone,
          userPwd: this.password,
        };
        this.$axios({
          url: "bm/pcLogin",
          method: "post",
          data: loginParam,
        })
          .then((res) => {
            // if (res.data.code != 0) {
            //   this.msgs = res.data.msg;
            //   this.deng = true;
            // }

            if (res.data.code == "200") {
              this.$store.commit("setUser", res.data.data.user);
              sessionStorage.setItem("user", res.data.data.user);
              // setCookie("user", res.data.data.user, -1);
              // setCookie("time", new Date().getTime(), -1);

              this.msgs = "登录成功";
              this.deng = true;

              this.$axios({
                url: "signup/apply/Detail",
                method: "get",
                params: {
                  user: res.data.data.user,
                },
              }).then((res) => {
                console.log(res);
                if (res.data.code == "200") {
                  let showTable = false;
                  if (res.data.data && res.data.data.info == null) {
                    sessionStorage.setItem("queryStatus", "0");
                    setTimeout(() => {

                      this.$router.replace({
                        path: "/src/views/reDetails.vue",
                      });
                    }, 500);
                    return
                  }
                  if (res.data.data && res.data.data.info) {
                    if (res.data.data.info.tijiao > 1) {
                      showTable = true;
                    }
                  }

                  if (showTable) {
                    this.$router.replace({
                      path: "/src/views/entryForm.vue",
                    });
                  } else {
                    if (res.data.data.info.tijiao == 1) {
                      sessionStorage.setItem("queryStatus", "1");
                      setTimeout(() => {
                        this.$router.replace({
                          path: "/src/views/reDetails.vue",
                        });
                      }, 500);
                      return
                    }
                  }
                } else {
                  this.msgs = res.data.msg;
                  this.deng = true;
                }
              });
            } else {
              this.msgs = res.data.msg;
              this.deng = true;

              // setTimeout(() => {
              //   this.$router.push({
              //     path: "/src/views/register.vue",
              //   });
              // }, 1000);
            }
          })
          .catch((err) => {
            console.log(err);
          });
        this.deng = true;
      } /*else if (this.cellPhone1 == "手机号正确" && this.yzm == this.yzm1) {
        let loginParam = {
          userPhone: this.cellPhone,
        };
        this.$axios({
          url: "bm/loginSmsPc",
          method: "post",
          data: loginParam,
        })
            .then((res) => {
              console.log('smspc', res.data.code, res.data.code == "200")
              if (res.data.code == "200") {
                this.$store.commit("setUser", res.data.data.user);
                localStorage.setItem("user", res.data.data.user);
                setCookie("user", res.data.data.user, -1);
                setCookie("time", new Date().getTime(), -1);
                this.msgs = "登录成功";
                this.deng = true;
                setTimeout(() => {
                  this.$router.push({
                    path: "/src/views/reDetails.vue",
                  });
                }, 1000);
              } else {
                this.msgs = res.data.msg;
                this.deng = true;

                setTimeout(() => {
                  this.$router.push({
                    path: "/src/views/register.vue",
                  });
                }, 1000);
              }
            })
            .catch((err) => {
              console.log(err);
            });
      }*/ else {
        this.deng = true;
      }
    },
    goes() {
      // if (this.cellPhone1 == "手机号正确") {
      //   var that = this;
      //   if (that.time !== 0) {
      //     this.$axios({
      //       url: "bm/sendAliyunSms",
      //       method: "get",
      //       params: {
      //         data: {userPhone: that.cellPhone},
      //       },
      //     })
      //         .then((res) => {
      //           if (res.data.code == "200") {
      //             that.yzm1 = res.data.data;
      //           }
      //         });
      //
      //     var count = setInterval(function () {
      //       var newtime = that.time--;
      //       that.num = newtime;
      //
      //       // that.data.time=time1
      //       if (that.time === 0) {
      //         that.time = 60;
      //         that.num = 60;
      //         clearInterval(count);
      //       }
      //     }, 1000);
      //   }
      // } else {
      //   var namess =
      //       /^((13[0-9])|(14[0-9])|(15([0-9]))|(16([0-9]))|(17([0-9]))|(18[0-9])|(19[0-9]))\d{8}$/;
      //   if (this.cellPhone == "") {
      //     this.msgs = "请填写手机号";
      //     this.deng = true;
      //   } else if (namess.test(this.cellPhone)) {
      //     this.cellPhone1 = "手机号正确";
      //     this.msgs = "短信已发送";
      //   } else {
      //     this.msgs = "手机格式不正确";
      //     this.deng = true;
      //   }
      // }
    },
  },
};
</script>
<style>
.ltitle .active {
  border-bottom: #83b7aa solid 4px;
  padding-bottom: 20px;
}

#layui-layer1 {
  position: fixed;
  margin: auto;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 19891015;
  width: 400px;
  height: 400px;
  background-color: #fff;
  -webkit-background-clip: content;
  border-radius: 2px;
  box-shadow: 1px 1px 50px rgb(0 0 0 / 30%);
}

.layui-layer-setwin a {
  text-decoration: none;
  color: #000;
  margin: 0px 7px;
}

.layui-form-item .layui-input-inline {
  width: 110px !important;
}

.signature-pad {
  width: 100%;
  height: 100%;
}

canvas {
  width: 100%;
  height: 200px;
  border-radius: 4px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.02) inset;
}
</style>
