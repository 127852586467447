
export function setCookie(name, value, expires){
    document.cookie = name+"="+value+";expires="+expires;
}

export function getCookie(name) {
    var arr=document.cookie.split('; ');
    var i=0;
    for(i=0;i<arr.length;i++) {
        var arr2=arr[i].split('=');
        
        if(arr2[0]==name)
        {
            return arr2[1];
        }
    }
    return '';
}

export function getValidUser() {
    var user = getCookie("user");
    var time = getCookie("time");
    if (user==null || user=='') return null;
    if (time==null || time=='') return null;
    
    var times = (new Date()).getTime() - Number(time);
    times = times / 1000 / 60;
    if (times>60) return null;      //登录有效期为60分钟
    return user;
}