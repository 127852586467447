import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user: localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : ""
  },
  getters: {},
  mutations: {
    setUser(state, newV) {
      state.user = newV
      localStorage.setItem("user", JSON.stringify(state.user))
    }
  },
  actions: {},
  modules: {}
})
