<template>
  <div style="background-color: #f7f7f7">
    <headers></headers>
    <div v-if="enabled" class="main1200" style="margin-bottom: 0">
      <div class="appraise_hears" >
        <img src="@/assets/img/hjmd_title.png" alt="" style="width: 700px;margin-left: -300px;" />
      </div>
    </div>
    <div v-if="enabled" class="main12301" :style="{ height: highr + 'px' }">
      <div class="cuosn">
        <img src="@/assets/img/11@1x.png" />
      </div>

      <el-table
        :data="tableData1"
        :header-cell-style="{ color: '#81BAAB', backgroundColor: '#EBF2F0' }"
        class="tabss"
        style="width: 100%"
        :cell-class-name="addClass"
      >
        <el-table-column type="indexss" align="center" label="" width="10">
        </el-table-column>
        <el-table-column type="index" align="center" label="序号" width="100">
        </el-table-column>
        <el-table-column type="indexss" align="center" label="" width="10">
        </el-table-column>
        <el-table-column
          prop="bmzpbh"
          align="center"
          label="作品编号"
          width="100"
        >
        </el-table-column>
        <el-table-column type="indexss" align="center" label="" width="50">
        </el-table-column>
        <el-table-column
          prop="trueName"
          label="作者姓名"
          width="200"
          style="text-indent: 20px"
        >
        </el-table-column>
        <el-table-column prop="yuanXiaoName" label="所在院校">
        </el-table-column>
        <el-table-column prop="zpMingCheng" label="作品名称"> </el-table-column>
      </el-table>
      <!--  -->
      <div class="cuosn" style="margin-top: 20px">
        <img src="@/assets/img/22@1x.png" />
      </div>
      <el-table
        :data="tableData2"
        :header-cell-style="{ color: '#81BAAB', backgroundColor: '#EBF2F0' }"
        class="tabss"
        style="width: 100%"
        :cell-class-name="addClass"
      >
        <el-table-column type="indexss" align="center" label="" width="10">
        </el-table-column>
        <el-table-column type="index" align="center" label="序号" width="100">
        </el-table-column>
        <el-table-column type="indexss" align="center" label="" width="10">
        </el-table-column>
        <el-table-column
          prop="bmzpbh"
          align="center"
          label="作品编号"
          width="100"
        >
        </el-table-column>
        <el-table-column type="indexss" align="center" label="" width="50">
        </el-table-column>
        <el-table-column
          prop="trueName"
          label="作者姓名"
          width="200"
          style="text-indent: 20px"
        >
        </el-table-column>
        <el-table-column prop="yuanXiaoName" label="所在院校">
        </el-table-column>
        <el-table-column prop="zpMingCheng" label="作品名称"> </el-table-column>
      </el-table>
      <!--  -->
      <div class="cuosn" style="margin-top: 20px">
        <img src="@/assets/img/33@1x.png" />
      </div>
      <el-table
        :data="tableData3"
        :header-cell-style="{ color: '#81BAAB', backgroundColor: '#EBF2F0' }"
        class="tabss"
        style="width: 100%"
        :cell-class-name="addClass"
      >
        <el-table-column type="indexss" align="center" label="" width="10">
        </el-table-column>
        <el-table-column type="index" align="center" label="序号" width="100">
        </el-table-column>
        <el-table-column type="indexss" align="center" label="" width="10">
        </el-table-column>
        <el-table-column
          prop="bmzpbh"
          align="center"
          label="作品编号"
          width="100"
        >
        </el-table-column>
        <el-table-column type="indexss" align="center" label="" width="50">
        </el-table-column>
        <el-table-column
          prop="trueName"
          label="作者姓名"
          width="200"
          style="text-indent: 20px"
        >
        </el-table-column>
        <el-table-column prop="yuanXiaoName" label="所在院校">
        </el-table-column>
        <el-table-column prop="zpMingCheng" label="作品名称"> </el-table-column>
      </el-table>
      <!--  -->
      <div class="cuosn" style="margin-top: 20px">
        <img src="@/assets/img/youxiu2@1x.png" />
      </div>
      <el-table
        :data="tableData4"
        :header-cell-style="{ color: '#81BAAB', backgroundColor: '#EBF2F0' }"
        class="tabss"
        style="width: 100%"
        :cell-class-name="addClass"
      >
        <el-table-column type="indexss" align="center" label="" width="10">
        </el-table-column>
        <el-table-column type="index" align="center" label="序号" width="100">
        </el-table-column>
        <el-table-column type="indexss" align="center" label="" width="10">
        </el-table-column>
        <el-table-column
          prop="bmzpbh"
          align="center"
          label="作品编号"
          width="100"
        >
        </el-table-column>
        <el-table-column type="indexss" align="center" label="" width="50">
        </el-table-column>
        <el-table-column
          prop="trueName"
          label="作者姓名"
          width="200"
          style="text-indent: 20px"
        >
        </el-table-column>
        <el-table-column prop="yuanXiaoName" label="所在院校">
        </el-table-column>
        <el-table-column prop="zpMingCheng" label="作品名称"> </el-table-column>
      </el-table>
    </div>
    <footers></footers>
  </div>
</template>
  <script>
import headers from "@/components/headers.vue";
import footers from "@/components/footers.vue";
export default {
  name: "appraise",
  data() {
    return {
      // 是否开始
      enabled:false,
      tableData1: [
        {
          bmzpbh: "0158",
          trueName: "卢啸",
          yuanXiaoName: "南京师范大学",
          zpMingCheng: "开沙村的盛夏",
        },
        {
          bmzpbh: "0175",
          trueName: "左晶晶",
          yuanXiaoName: "盐城师范学院",
          zpMingCheng: "老船",
        },
        // {
        //   bmzpbh: "0357",
        //   trueName: "杨晨欣",
        //   yuanXiaoName: "南通大学",
        //   zpMingCheng: "绿色心情",
        // },
        {
          bmzpbh: "0381",
          trueName: "季颖",
          yuanXiaoName: "南京艺术学院",
          zpMingCheng: "黄窝印象",
        },
        {
          bmzpbh: "0461",
          trueName: "申荟群",
          yuanXiaoName: "江苏师范大学",
          zpMingCheng: "匠心传承",
        },
        {
          bmzpbh: "0540",
          trueName: "吴云",
          yuanXiaoName: "南京晓庄学院",
          zpMingCheng: "理发店一角",
        },
        {
          bmzpbh: "1340",
          trueName: "光昇",
          yuanXiaoName: "南京大学",
          zpMingCheng: "老街新象",
        },
        {
          bmzpbh: "1412",
          trueName: "沈一雯",
          yuanXiaoName: "南京大学",
          zpMingCheng: "高秋",
        },
        // {
        //   bmzpbh: "1448",
        //   trueName: "张璟璇",
        //   yuanXiaoName: "南京师范大学",
        //   zpMingCheng: "蓝色湖边",
        // },
      ], //一等奖
      tableData2: [
        {
          bmzpbh: "0002",
          trueName: "宗建成",
          yuanXiaoName: "南京大学",
          zpMingCheng: "石山下米糕店",
        },
        {
          bmzpbh: "0258",
          trueName: "薛妮",
          yuanXiaoName: "江苏大学",
          zpMingCheng: "故园春居",
        },
        {
          bmzpbh: "0260",
          trueName: "张春浩",
          yuanXiaoName: "江苏师范大学",
          zpMingCheng: "伊镇·倪园",
        },
        // {
        //   bmzpbh: "0270",
        //   trueName: "谈晔晔",
        //   yuanXiaoName: "江苏师范大学",
        //   zpMingCheng: "行尽江南·水潺潺",
        // },
        // {
        //   bmzpbh: "0287",
        //   trueName: "谢明丽",
        //   yuanXiaoName: "南京艺术学院",
        //   zpMingCheng: "亭亭如盖",
        // },
        {
          bmzpbh: "0328",
          trueName: "胡琳冉",
          yuanXiaoName: "南京艺术学院",
          zpMingCheng: "村庄",
        },
        {
          bmzpbh: "0386",
          trueName: "张伟",
          yuanXiaoName: "南京艺术学院",
          zpMingCheng: "乡音",
        },
        // {
        //   bmzpbh: "0420",
        //   trueName: "张天依",
        //   yuanXiaoName: "苏州工艺美术职业技术学院",
        //   zpMingCheng: "运河边上静悄悄",
        // },
        {
          bmzpbh: "0637",
          trueName: "任红",
          yuanXiaoName: "南京旅游职业学院",
          zpMingCheng: "小桥·流水·人家",
        },
        {
          bmzpbh: "0930",
          trueName: "MOON SUNG DOO文圣斗",
          yuanXiaoName: "南京艺术学院",
          zpMingCheng: "巴斗村",
        },
        // {
        //   bmzpbh: "1029",
        //   trueName: "何雁",
        //   yuanXiaoName: "江苏师范大学",
        //   zpMingCheng: "房舍如舟",
        // },
        {
          bmzpbh: "1036",
          trueName: "程梦凡",
          yuanXiaoName: "江苏第二师范学院",
          zpMingCheng: "山村新貌",
        },
        {
          bmzpbh: "1276",
          trueName: "孙乐言",
          yuanXiaoName: "盐城工学院",
          zpMingCheng: "故里桦墅",
        },
        {
          bmzpbh: "1300",
          trueName: "王熙瑶",
          yuanXiaoName: "南通师范高等专科学校",
          zpMingCheng: "水韵",
        },
        // {
        //   bmzpbh: "1397",
        //   trueName: "师千淳",
        //   yuanXiaoName: "江苏师范大学",
        //   zpMingCheng: "万家罗列众志诚",
        // },
        {
          bmzpbh: "1473",
          trueName: "舒典",
          yuanXiaoName: "淮阴工学院",
          zpMingCheng: "水韵",
        },
        {
          bmzpbh: "1476",
          trueName: "黄天",
          yuanXiaoName: "江苏第二师范学院",
          zpMingCheng: "佘村印象",
        },
        {
          bmzpbh: "1645",
          trueName: "MOONSUNGDON 文圣敦",
          yuanXiaoName: "南京艺术学院",
          zpMingCheng: "风景",
        },
        {
          bmzpbh: "1789",
          trueName: "花会雨",
          yuanXiaoName: "江苏第二师范学院",
          zpMingCheng: "佘村小景",
        },
        {
          bmzpbh: "1820",
          trueName: "张馨月",
          yuanXiaoName: "苏州工艺美术职业技术学院",
          zpMingCheng: "无题",
        },
        {
          bmzpbh: "1871",
          trueName: "滕子威",
          yuanXiaoName: "南通大学",
          zpMingCheng: "旧址新风",
        },
      ], //二等奖
      tableData3: [
        {
          bmzpbh: "0229",
          trueName: "吴天木",
          yuanXiaoName: "南京传媒学院",
          zpMingCheng: "新义圩——十六景",
        },
        {
          bmzpbh: "0237",
          trueName: "徐心媛",
          yuanXiaoName: "南通大学",
          zpMingCheng: "乌篷荡漾",
        },
        {
          bmzpbh: "0262",
          trueName: "徐丹茹",
          yuanXiaoName: "南京大学",
          zpMingCheng: "石山下的河塘上",
        },
        {
          bmzpbh: "0439",
          trueName: "胡晶晶",
          yuanXiaoName: "常州大学",
          zpMingCheng: "花都馨苑",
        },
        {
          bmzpbh: "0450",
          trueName: "周璇",
          yuanXiaoName: "常州纺织服装职业技术学院",
          zpMingCheng: "文脉",
        },
        {
          bmzpbh: "0490",
          trueName: "许佳敏",
          yuanXiaoName: "常州纺织服装职业技术学院",
          zpMingCheng: "郑陆镇牟家村、袁家村、江家湾美景",
        },
        {
          bmzpbh: "0507",
          trueName: "马晨瑜",
          yuanXiaoName: "扬州大学",
          zpMingCheng: "方巷春来",
        },
        {
          bmzpbh: "0546",
          trueName: "黄千容",
          yuanXiaoName: "南通师范高等专科学校",
          zpMingCheng: "意象晨曦",
        },
        {
          bmzpbh: "0590",
          trueName: "施锦诚",
          yuanXiaoName: "淮阴工学院",
          zpMingCheng: "午后小溪",
        },
        {
          bmzpbh: "0649",
          trueName: "王悦悦",
          yuanXiaoName: "常州纺织服装职业技术学院",
          zpMingCheng: "乡村一隅",
        },
        {
          bmzpbh: "0663",
          trueName: "衡星如",
          yuanXiaoName: "苏州工艺美术职业技术学院",
          zpMingCheng: "雨中的歇马桥村",
        },
        {
          bmzpbh: "0694",
          trueName: "赵思圆",
          yuanXiaoName: "中国矿业大学",
          zpMingCheng: "紫山印象",
        },
        {
          bmzpbh: "0856",
          trueName: "殷妍",
          yuanXiaoName: "江苏师范大学",
          zpMingCheng: "新农村新面貌",
        },
        {
          bmzpbh: "0913",
          trueName: "吴悠",
          yuanXiaoName: "东南大学",
          zpMingCheng: "一一风荷举",
        },
        {
          bmzpbh: "0943",
          trueName: "王颖",
          yuanXiaoName: "江苏城市职业学院",
          zpMingCheng: "幽静的小山村",
        },
        {
          bmzpbh: "0972",
          trueName: "吴萌",
          yuanXiaoName: "南京师范大学",
          zpMingCheng: "绿意水乡",
        },
        {
          bmzpbh: "1054",
          trueName: "高新宇",
          yuanXiaoName: "扬州市职业大学",
          zpMingCheng: "水乡鹅家",
        },
        {
          bmzpbh: "1065",
          trueName: "李培良",
          yuanXiaoName: "扬州大学",
          zpMingCheng: "暮色霞光",
        },
        {
          bmzpbh: "1213",
          trueName: "魏玉彤",
          yuanXiaoName: "苏州工艺美术职业技术学院",
          zpMingCheng: "印象·芦苇荡",
        },
        {
          bmzpbh: "1474",
          trueName: "田紫",
          yuanXiaoName: "淮阴工学院",
          zpMingCheng: "平凡无奇的乡村里，藏着最惬意的生活",
        },
        {
          bmzpbh: "1475",
          trueName: "刘仰坤",
          yuanXiaoName: "盐城师范学院",
          zpMingCheng: "瑞雪陡山村",
        },
        {
          bmzpbh: "1705",
          trueName: "田景雯",
          yuanXiaoName: "南京工业大学",
          zpMingCheng: "红色李巷——革命痕迹",
        },
        {
          bmzpbh: "1714",
          trueName: "李梦涵",
          yuanXiaoName: "盐城师范学院",
          zpMingCheng: "丰收",
        },
        {
          bmzpbh: "1717",
          trueName: "戎炫",
          yuanXiaoName: "南京工业大学",
          zpMingCheng: "烟雨入江南",
        },
        {
          bmzpbh: "1748",
          trueName: "张钰莹",
          yuanXiaoName: "南京工业大学",
          zpMingCheng: "白墙灰砖红瓦",
        },
        {
          bmzpbh: "1791",
          trueName: "罗天瑞",
          yuanXiaoName: "淮阴工学院",
          zpMingCheng: "流水人家",
        },
      ], //三等奖
      tableData4: [
        {
          bmzpbh: "0038",
          trueName: "顾锡",
          yuanXiaoName: "南京工程学院",
          zpMingCheng: "映象幸福",
        },
        {
          bmzpbh: "0056",
          trueName: "冯玉娇",
          yuanXiaoName: "东南大学成贤学院",
          zpMingCheng: "草坪上",
        },
        // {
        //   bmzpbh: "0155",
        //   trueName: "浦皓哲",
        //   yuanXiaoName: "南京工业大学",
        //   zpMingCheng: "静",
        // },
        {
          bmzpbh: "0201",
          trueName: "宋心怡",
          yuanXiaoName: "江苏第二师范学院",
          zpMingCheng: "骥渚·印象",
        },
        // {
        //   bmzpbh: "0308",
        //   trueName: "曾琼蝉",
        //   yuanXiaoName: "南京工业大学",
        //   zpMingCheng: "绿巷",
        // },
        {
          bmzpbh: "0320",
          trueName: "李佳宜",
          yuanXiaoName: "东南大学",
          zpMingCheng: "路",
        },
        {
          bmzpbh: "0401",
          trueName: "杨家兴",
          yuanXiaoName: "南京晓庄学院",
          zpMingCheng: "乡村一角",
        },
        {
          bmzpbh: "0432",
          trueName: "高欣",
          yuanXiaoName: "常州大学",
          zpMingCheng: "花都新貌",
        },
        {
          bmzpbh: "0456",
          trueName: "戴曾怡",
          yuanXiaoName: "南京师范大学",
          zpMingCheng: "凉蓬一景",
        },
        {
          bmzpbh: "0515",
          trueName: "蒋雨翔",
          yuanXiaoName: "江苏师范大学",
          zpMingCheng: "汉王泉韵",
        },
        {
          bmzpbh: "0535",
          trueName: "李明雨",
          yuanXiaoName: "江苏第二师范学院",
          zpMingCheng: "归乡之路",
        },
        {
          bmzpbh: "0587",
          trueName: "杨婧",
          yuanXiaoName: "南京艺术学院",
          zpMingCheng: "绿",
        },
        {
          bmzpbh: "0634",
          trueName: "杨林果",
          yuanXiaoName: "东南大学",
          zpMingCheng: "水色人家",
        },
        {
          bmzpbh: "0651",
          trueName: "潘简约",
          yuanXiaoName: "南京师范大学",
          zpMingCheng: "一角",
        },
        {
          bmzpbh: "0698",
          trueName: "唐嘉苓",
          yuanXiaoName: "南京师范大学",
          zpMingCheng: "草塘黄昏",
        },
        {
          bmzpbh: "0729",
          trueName: "毛声勇",
          yuanXiaoName: "宿迁学院",
          zpMingCheng: "乡野屋房",
        },
        {
          bmzpbh: "0783",
          trueName: "胡晓炜",
          yuanXiaoName: "宿迁学院",
          zpMingCheng: "印象——乡村",
        },
        {
          bmzpbh: "0815",
          trueName: "李天骄",
          yuanXiaoName: "东南大学",
          zpMingCheng: "长卷·屋荫入画",
        },
        {
          bmzpbh: "0844",
          trueName: "钱思静",
          yuanXiaoName: "南京特殊教育师范学院",
          zpMingCheng: "红瓦白墙山海间·黄窝村",
        },
        {
          bmzpbh: "0950",
          trueName: "刘世杰",
          yuanXiaoName: "江苏师范大学",
          zpMingCheng: "波粼",
        },
        {
          bmzpbh: "0971",
          trueName: "黄麒颖",
          yuanXiaoName: "南京特殊教育师范学院",
          zpMingCheng: "陆巷岁月",
        },
        {
          bmzpbh: "0976",
          trueName: "陆思静",
          yuanXiaoName: "东南大学成贤学院",
          zpMingCheng: "晴空阳澄",
        },
        {
          bmzpbh: "1009",
          trueName: "唐诗韵",
          yuanXiaoName: "淮阴师范学院",
          zpMingCheng: "绿调村庄",
        },
        {
          bmzpbh: "1047",
          trueName: "王雨菲",
          yuanXiaoName: "南京信息工程大学",
          zpMingCheng: "教育是百年大计",
        },
        // {
        //   bmzpbh: "1108",
        //   trueName: "苏腾",
        //   yuanXiaoName: "苏州工艺美术职业技术学院",
        //   zpMingCheng: "光",
        // },
        {
          bmzpbh: "1183",
          trueName: "张慧怡",
          yuanXiaoName: "南京晓庄学院",
          zpMingCheng: "李巷的天空",
        },
        {
          bmzpbh: "1263",
          trueName: "朱奕瑾",
          yuanXiaoName: "宿迁学院",
          zpMingCheng: "霞光·村光",
        },
        {
          bmzpbh: "1460",
          trueName: "徐瑞",
          yuanXiaoName: "宿迁学院",
          zpMingCheng: "建设西街",
        },
        {
          bmzpbh: "1477",
          trueName: "孙地平",
          yuanXiaoName: "宿迁学院",
          zpMingCheng: "淳风",
        },
        {
          bmzpbh: "1518",
          trueName: "马艺榕",
          yuanXiaoName: "江苏建筑职业技术学院",
          zpMingCheng: "喜迎二十大的倪园村",
        },
        {
          bmzpbh: "1607",
          trueName: "钟璐意",
          yuanXiaoName: "南京工业大学",
          zpMingCheng: "乡村印迹",
        },
        {
          bmzpbh: "1617",
          trueName: "李媛媛",
          yuanXiaoName: "江苏农林职业技术学院",
          zpMingCheng: "七里巷",
        },
        {
          bmzpbh: "1631",
          trueName: "Carolina Cárdenas",
          yuanXiaoName: "南京艺术学院",
          zpMingCheng: "风景",
        },
        {
          bmzpbh: "1707",
          trueName: "宋安琪",
          yuanXiaoName: "江苏农林职业技术学院",
          zpMingCheng: "小镇故事“多”",
        },
        {
          bmzpbh: "1719",
          trueName: "李芳葳",
          yuanXiaoName: "南京工业大学",
          zpMingCheng: "茶香黄龙岘",
        },
        {
          bmzpbh: "1731",
          trueName: "徐煜明",
          yuanXiaoName: "东南大学成贤学院",
          zpMingCheng: "天青色等烟雨",
        },
        {
          bmzpbh: "1763",
          trueName: "盛越颖",
          yuanXiaoName: "南京工业大学",
          zpMingCheng: "乡村大道",
        },
        {
          bmzpbh: "1810",
          trueName: "庄雯静",
          yuanXiaoName: "江苏农林职业技术学院",
          zpMingCheng: "坠夕阳",
        },
        {
          bmzpbh: "1815",
          trueName: "闫雨",
          yuanXiaoName: "南京林业大学",
          zpMingCheng: "乡村夜色",
        },
      ], //优秀奖
      currentPage: 1,
      highr: document.body.clientHeight - 235,
      page: 1,
      limit: 10,
      total: 0,
    };
  },
  components: {
    headers,
    footers,
  },

  mounted() {
    // this.highr = document.body.clientHeight - 265;
    // this.getCpVoteVoList();
  },

  methods: {
    //刷新数据
    // getCpVoteVoList() {
    //   this.$axios
    //     .get(
    //       "http://47.111.169.186:8870/njdqhxc/api/vote/getFirstTrialList",
    //       {
    //         params: {
    //           page: this.page,
    //           limit:this.limit,
    //           data:{}
    //         },
    //       }
    //     )
    //     .then((res) => {
    //       console.log(res.data);
    //       if (res.data.code == "200") {
    //        this.total = res.data.data.total
    //        this.tableData = res.data.data
    //       }
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //     });
    // },
  },
};
</script>
  <style>
.bottomb {
  width: 100%;
  height: 30px;
  background: #fff;
  line-height: 30px;
  text-align: center;
}
.appraise_hears {
  margin: auto;
  height: 65px;
  border-bottom: #efefef solid 1px;
  position: relative;
}
.appraise_hears img {
  width: 191px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -15.5px;
  margin-left: -95.5px;
}
.main12301 {
  width: 1200px;
  background-color: #ffffff;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 0;
  position: relative;
  overflow-x: hidden;
  overflow-y: scroll;
}
.main12301::-webkit-scrollbar {
  display: none;
}
.cuosn {
  width: 100%;
  height: 42px;
  position: relative;
  margin-bottom: 10px;
}
.cuosn img {
  width: 272px;
  height: 42px;
  position: absolute;
  top: 0;
  left: 50%;
  margin-left: -136px;
}
.tabss {
  overflow-x: hidden !important;
  overflow-y: scroll !important;
}
.tabss::-webkit-scrollbar {
  display: none;
}
.el-table__body-wrapper::-webkit-scrollbar {
  width: 0;
  background: rgba(213, 215, 220, 0.3);
  border: none;
}
.el-table__body-wrapper::-webkit-scrollbar-track {
  border: none;
}
.el-table th.gutter {
  display: none;
  width: 0;
}
.el-table colgroup col[name="gutter"] {
  display: none;
  width: 0;
}

.el-table__body {
  width: 100% !important;
}
</style>
