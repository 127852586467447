<template>
  <el-dialog :show-close="false" :visible.sync="deng" class="tipe_dialog">
    <div>
      <img src="@/assets/img/success.png" alt="" />
      <p>{{ tipsTitle }}</p>
      <div v-if="tipsTitle == '暂存成功'" @click="handleClose" class="button">
        我知道了
      </div>
      <div v-else @click="handleCheck" class="button">查看报名</div>
    </div>
  </el-dialog>
</template>

<script>
export default {
  props: {
    deng: {
      type: Boolean,
    },
    tipsTitle: {
      type: String,
    },
  },
  data() {
    return {};
  },
  methods: {
    // 蒙版
    handleClose() {
      this.$emit("takeTips");
    },
    handleCheck() {
      this.$emit("takeTips");
      this.$router.replace({
        path: "/src/views/entryForm.vue",
      });
    },
  },
};
</script>

<style lang="scss">
.tipe_dialog {
  > div {
    display: flex; /* 将容器设置为 Flex 容器 */
    justify-content: center; /* 水平居中 */
    align-items: center; /* 垂直居中 */
  }
  img {
    width: 102px;
    margin-top: 20px;
  }
  p {
    text-indent: 0px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 600;
    font-size: 20px;
    color: #222222;
    line-height: 28px;
    text-align: center;
    font-style: normal;
    margin-top: 20px;
  }
  .button {
    width: 118px;
    height: 40px;
    background: linear-gradient(90deg, #80af89 0%, #7a9f81 100%);
    border-radius: 20px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    font-size: 16px;
    color: #ffffff;
    line-height: 40px;
    text-align: center;
    font-style: normal;
    margin-top: 20px;
    cursor: pointer;
  }
}
.tipe_dialog .el-dialog {
  transform: none;
  left: 0 !important;
  position: relative !important;
  margin: 0 auto !important;
}
.tipe_dialog .el-dialog .el-dialog__body {
  overflow-y: auto !important;
  overflow-x: hidden !important;
}
.tipe_dialog .el-dialog__footer {
  width: auto !important;
}
.tipe_dialog .el-dialog {
  border-radius: 16px;
  display: flex !important;
  flex-direction: column !important;
  margin: 0 !important;
  position: absolute !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
  width: 240px;
  height: 265px;
}
.tipe_dialog .el-dialog .el-dialog__body {
  padding: 20px 20px !important;
  flex: 1;
  overflow: auto;
}
.tipe_dialog .el-dialog__header {
  // padding: 0 !important;
  padding: 0px;
}
.tipe_dialog .el-dialog__headerbtn .el-dialog__close {
  font-size: 25px !important;
  font-weight: bolder;
}
.tipe_dialog .el-dialog__title {
  font-weight: 600;
  font-size: 22px !important;
}
</style>
