<template>
  <div style="background-color: #f7f7f7">
    <headers></headers>
    <div class="main1200" style="margin-bottom: 30px">
      <div class="newstitle">
        <div class="newstitle_left left" style="width: 60%">
          创作对象
<!--          <span class="text-gray text-xl">-->
<!--            ( 标-->
<!--            <img-->
<!--              src="../assets/img/star.png"-->
<!--              height="22"-->
<!--              width="22"-->
<!--              style="vertical-align: middle"-->
<!--            />为具有红色印记村庄 )</span>-->
        </div>
        <div
          class="
            ntitle_coin
            fr
            text-gray text-xl text-right
            padding-right padding-top-sm
          "
        >
          <span class=""> 点击图片查看村庄位置</span>
          <!--            <span class="cuIcon cuIcon-right"></span>-->
        </div>
      </div>
      <div class="newscontent">
        <div class="czmenu clearfix">
          <ul class="flex justify-between">
            <li
              v-for="(item, index) in list"
              :key="index"
              @click="getDatas(1, item.id, index)"
            >
              <router-link to="#" :class="{ active: clickNum == index }">{{
                item.name
              }}</router-link>
            </li>
          </ul>
        </div>

        <div class="row" id="content" style="height: 720px">
          <ul>
            <div class="col-sm-4" v-for="(item, index) in lists" :key="index">
              <router-link to="javascript:;" target="_blank" tag="div">
                <div class="cjimg">
                  <img
                    :src="item.picurl"
                    width="350"
                    height="160"
                    @click="go(item.pointX, item.pointY, item.mapAddress)"
                  />
                </div>
                <div class="cztitle text-black">
                  <img
                    src="../assets/img/star.png"
                    height="18"
                    v-show="item.hong == 1"
                    style="vertical-align: center"
                  />
                  {{ item.title }}
                </div>
              </router-link>
            </div>
          </ul>
        </div>
        <div class="text-right">
          <div class="myPagination">
            <el-pagination
              small
              layout="prev, pager, next"
              :total="nums"
              @prev-click="tabDatas"
              @next-click="tabDataLists"
              @current-change="tabClicks"
              :page-size="pageNum"
              :current-page.sync="page"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
    <footers></footers>
  </div>
</template>
<script>
import headers from "@/components/headers.vue";
import footers from "@/components/footers.vue";
export default {
  name: "create",
  data() {
    return {
      list: {},
      lists: {},
      nums: 0,
      pageNum: 9,
      num: "",
      page:1,
      clickNum: 0,
    };
  },
  components: {
    headers,
    footers,
  },

  mounted() {
    this.getData();
    this.getDatas(1, 15, 0);
  },

  methods: {
    getData() {
      this.$axios({
        url:"chuangzuo/getCityList",
        method:"get",
        params:{
          data:{},
        }
      })
        .then((res) => {
          if (res.data.code == "200") {
            // console.log(res, "qqqqqqq");
            this.list = res.data.data;
          }
        });
    },
    getDatas(e, a, index) {
      this.clickNum = index;
        this.$axios({
          url:"chuangzuo/getChuangZuoList",
          method:"get",
          params:{
            data: { cityId: a },
            page: e,
            limit: 9,
          }
        })
        .then((res) => {
          if (res.data.code == "200") {
            console.log(res);
            this.lists = res.data.data.list;
            this.nums = res.data.data.total;
            this.num = a;
            this.page = e;
          }
        });
    },
    tabDatas(e) {
      this.getDatas(e, this.num, this.clickNum);
    },
    tabDataLists(e) {
      this.getDatas(e, this.num, this.clickNum);
    },
    tabClicks(e) {
      // console.log(e);
      this.getDatas(e, this.num, this.clickNum);
    },
    go(a, b, c) {
      // console.log(a,b,c);
      // this.$router.push({
      //     path: "/src/views/maps.vue",
      //     query: {
      //         Longitude : a,
      //         latitude : b,
      //         title : c,
      //     },
      // });

      let routeData = this.$router.resolve({
        path: "/src/views/maps.vue",
        query: {
          Longitude: a,
          latitude: b,
          title: c,
        },
      });
      window.open(routeData.href, "_blank");
    },
  },
};
</script>
<style>
.czmenu {
  margin-bottom: 40px;
}

.justify-between {
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
}

.czmenu li {
  float: left;
  margin-right: 10px;
  list-style: none;
}

.czmenu a {
  color: #fff;
}

.czmenu .active {
  background-color: #ffc300;
}

.cztitle {
  font-size: 16px;
}
</style>
