<template>
  <div style="background-color: #f7f7f7">
    <headers></headers>
    <div class="main1200" style="margin-bottom: 0; padding: 20px 30px 0">
      <div class="appraise_hear">
        <img src="@/assets/wltp.png" alt="" />
      </div>
    </div>
    <div class="main1230" :style="{ height: highr + 'px' }">
      <div class="rankings">
        <div
          class="rankings_box"
          v-for="(item, index) in rankList"
          :key="item.code"
        >
          <div :class="index+1<21?'rankings_box_p':'rankings_box_ps'">
            {{ index + 1 }}
          </div>
          <div class="rankings_box_pian">{{ item.code }}</div>
          <div class="rankings_box_wen">{{ item.author }}</div>
          <div class="rankings_box_sum">{{ item.voteNum }}票</div>
        </div>
      </div>
    </div>
    <footers></footers>
  </div>
</template>
  <script>
import headers from "@/components/headers.vue";
import footers from "@/components/footers.vue";
import utilMd5 from "@/assets/md5.js";
export default {
  name: "appraise",
  data() {
    return {
      tableData: [],
      currentPage: 1,
      highr: document.body.clientHeight - 215,
      rankList: [],
    };
  },
  components: {
    headers,
    footers,
  },

  mounted() {
    this.getCpVoteVoList();
  },

  methods: {
    //刷新数据
    getCpVoteVoList() {
      var timestamp = Math.trunc(Date.now() / 1000);
      var stringSignTemp =
        "opo_N5M70YlBdMRpvCiNgtZij6qs" +
        timestamp +
        "a4f605d055834067b306967ebad3f396db4966ba39ba4d39a63f4a7e61d80914";
      this.$axios({
        url:"voteip/opus/ranking",
        method:"get",
        params:{
          data: {
            openId: "opo_N5M70YlBdMRpvCiNgtZij6qs",
            page: 1,
            limit: 183,
            wordKey: this.input,
            sign: utilMd5.hexMD5(stringSignTemp).toUpperCase(),
            timestamp: timestamp,
          },
        }
      })
        .then((res) => {
          console.log(res.data);
          if (res.data.code == "200") {
            this.rankList = res.data.data
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
  <style scoped>
.bottomb {
  width: 100%;
  height: 30px;
  background: #fff;
  line-height: 30px;
  text-align: center;
}
.appraise_hear {
  margin: auto;
  height: 65px;
  border-bottom: #efefef solid 1px;
  position: relative;
}
.appraise_hear img {
  width: 189px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -15.5px;
  margin-left: -94.5px;
}
.main1230 {
  width: 1200px;
  background-color: #ffffff;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 0px !important;
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;
  background-image: url('../assets/ditu.png');
}
.main1230::-webkit-scrollbar {
  display: none; /* Chrome Safari */
}
.tabss {
  overflow-x: hidden !important;
  overflow-y: scroll !important;
}
.tabss::-webkit-scrollbar {
  display: none;
}
.el-table__body-wrapper::-webkit-scrollbar {
  width: 0;
  background: rgba(213, 215, 220, 0.3);
  border: none;
}
.el-table__body-wrapper::-webkit-scrollbar-track {
  border: none;
}
.el-table th.gutter {
  display: none;
  width: 0;
}
.el-table colgroup col[name="gutter"] {
  display: none;
  width: 0;
}

.el-table__body {
  width: 100% !important;
}
.rankings {
  width: 50%;
  margin: 0 auto;
  background-color: rgba(255, 255, 255, 0.4);
  box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.3);
}
.rankings_box {
  width: 100%;
  height: 36px;
  display: flex;
  border-bottom: 2px solid #eeeeee;
  position: relative;
}

.rankings_box_p {
  width: 20px;
  height: 20px;
  margin: 8px 12px 8px 24px;
  line-height: 20px;
  background-color: rgba(129, 186, 171, 1);
  color: #fff;
  text-align: center;
  font-size: 12px;
  border-radius: 50%;
}
.rankings_box_ps {
  width: 20px;
  height: 20px;
  margin: 8px 12px 8px 24px;
  line-height: 20px;
  background-color: rgba(236, 236, 236, 1);
  color: rgba(153, 153, 153, 1);
  text-align: center;
  font-size: 12px;
  border-radius: 50%;
}
.rankings_box_pian {
  width: 40px;
  height: 19px;
  line-height: 19px;
  text-align: center;
  background: #ebf2f0;
  font-size: 14px;
  font-weight: 500;
  color: #81baab;
  margin: 9px 12px;
  border-radius: 8px;
}

.rankings_box_wen {
  font-size: 12px;
  line-height: 17px;
  margin: 9.5px 15px;
  font-weight: 500;
}

.rankings_box_sum {
  font-size: 12px;
  line-height: 17px;
  margin: 9.5px 40px;
  font-weight: 500;
  color: #81baab;
  position: absolute;
  top: 0;
  right: 0;
}
</style>
