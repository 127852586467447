import Vue from 'vue'
import VueRouter from 'vue-router'
// import HomeView from '../views/HomeView.vue'
import index from '@/views/index.vue'
import active from '@/views/active.vue'
import appraise from '@/views/appraise.vue'
import award from '@/views/award.vue'
import create from '@/views/create.vue'
import initialAudit from '@/views/initialAudit.vue'
import reEvaluation from '@/views/reEvaluation.vue'
import showroom from '@/views/showroom.vue'
import signUp from '@/views/signUp.vue'
import vote from '@/views/vote.vue'
import register from '@/views/register.vue'
import qqmaps from '@/views/maps.vue'
import reDetails from '@/views/reDetails.vue'
import results from '@/views/results.vue'
import results_award from "@/views/results_award";
import entryForm from "@/views/entryForm";
import reDetailsTwo from '@/views/reDetailsTwo.vue'
import forget_pwd from '@/views/forget_pwd.vue'
import modifyWork from '@/views/modifyWork.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'index',
    component: index
  },{
    path:"/src/views/index.vue",
    component:index,
    name:"index"
  },{
    path:"/src/views/active.vue",
    component:active,
    name:"active"
  },{
    path:"/src/views/appraise.vue",
    component:appraise,
    name:"appraise"
  },{
    path:"/src/views/award.vue",
    component:award,
    name:"award"
  },{
    path:"/src/views/create.vue",
    component:create,
    name:"create"
  },{
    path:"/src/views/initialAudit.vue",
    component:initialAudit,
    name:"initialAudit"
  },{
    path:"/src/views/reEvaluation.vue",
    component:reEvaluation,
    name:"reEvaluation"
  },{
    path:"/src/views/showroom.vue",
    component:showroom,
    name:"showroom"
  },{
    path:"/src/views/signUp.vue",
    component:signUp,
    name:"signUp"
  },{
    path:"/src/views/vote.vue",
    component:vote,
    name:"vote"
  },{
    path:"/src/views/register.vue",
    component:register,
    name:"register"
  },{
    path:"/src/views/maps.vue",
    component:qqmaps,
    name:"qqmaps"
  },{
    path:"/src/views/reDetails.vue",
    component:reDetails,
    name:"reDetails"
  },{
    path:"/src/views/results.vue",
    component:results,
    name:"results"
  },{
    path:"/src/views/results_award.vue",
    component:results_award,
    name:"results_award"
  },{
    path:"/src/views/entryForm.vue",
    component:entryForm,
    name:"entryForm"
  },
  {
    path:"/src/views/reDetailsTwo.vue",
    component:reDetailsTwo,
    name:"reDetailsTwo"
  },
  {
    path:"/src/views/forget_pwd.vue",
    component:forget_pwd,
    name:"forgetPassword"
  },
  {
    path:"/src/views/modifyWork.vue",
    component:modifyWork,
    name:"modifyWork"
  },
  {
    path:"/galleryAward", // 线上展厅，往届作品展览
    component:() => import('@/views/gallery_award.vue'),
    name:"galleryAward"
  }
]

const router = new VueRouter({
  // mode: 'history',
  base: "/",
  mode: "hash",
  scrollBehavior: () => ({ y: 0 }),
  // base: process.env.BASE_URL,
  routes
})

export default router
