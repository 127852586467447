<template>
  <div>
    <div class="allbg">
      <div class="h100"></div>
      <div class="kuang1 shadow-warp bg-white overflow padding-xl">
        <div class="k1title text-right margin-right margin-top">
          <router-link to="/src/views/index.vue">
            <i class="el-icon-close"></i>
          </router-link>
        </div>
        <div class="text-sl text-bold">第三届</div>
        <div class="text-sl text-bold">丹青妙笔绘田园乡村</div>
        <div
          class="
            padding
            flex
            justify-around
            margin-xl
            text-xxl
            ltitle
            margin-bottom-xs
          "
        >
          <div class="">注册</div>
        </div>
        <div class="padding-xl padding-top-0">
          <form
            class="login-form"
            role="form"
            name="register_form"
            method="post"
            id="register_form"
          >
            <div class="input-group-lg">
              <input
                name="phone"
                type="text"
                class="form-control radius"
                placeholder="请输入手机号"
                required
                id="phone"
                v-model="cellPhone"
                @blur="models"
              />
              <p></p>
            </div>
            <div class="input-group-lg margin-top">
              <input
                name="pass"
                type="password"
                class="form-control radius"
                placeholder="请输入您的新的密码"
                required
                id="pass"
                v-model="password"
                @blur="inpt"
              />
              <p></p>
            </div>
            <div class="input-group-lg margin-top">
              <input
                name="pass2"
                type="password"
                class="form-control radius"
                placeholder="请再次输入您的新的密码"
                required
                id="pass2"
                v-model="newPassword"
                @blur="inpts"
              />
              <p></p>
            </div>
            <div class="input-group-lg margin-top" style="position: relative">
              <div style="position: absolute; right: 20px; top: 11px" id="">
                <input
                  v-if="num!=60"
                  type="button"
                  :value="num"
                  class="cu-btn sm bg-grey radius"
                />
                <input
                  v-if="num==60"
                  type="button"
                  value="获取验证码"
                  @click="goes"
                  class="cu-btn sm bg-grey radius"
                />
              </div>
              <input
                name="vercode"
                type="text"
                maxlength="4"
                class="form-control radius"
                placeholder="请输入手机验证码"
                required
                id="vercode"
                v-model="code"
                @blur="codes"
              />
            </div>
            <div class="text-gray margin-top text-right">
              已有帐号？<router-link
                to="/src/views/signUp.vue"
                class="text-black"
                >快速登陆</router-link
              >
            </div>
            <div style="height: 40px"></div>
            <div class="margin-top flex">
              <button
                class="cu-btn bg-grey round lg flex-sub padding"
                id="login_btn"
                type="button"
                @click="btn()"
              >
                注册
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div
      id="modal1653222159963"
      class="modal fade in"
      role="dialog"
      aria-labelledby="modalLabel"
      style="z-index: 2147483647; display: block; padding-right: 17px"
      v-show="deng"
    >
      <div class="modal-dialog" style="width: 200px">
        <div class="modal-content" style="border-radius: 20px">
          <div class="modal-header" style="display: none">
            <button type="button" class="close" data-dismiss="modal">
              <span aria-hidden="true">×</span
              ><span class="sr-only">Close</span>
            </button>
            <h4 class="modal-title" id="modalLabel">提示</h4>
          </div>
          <div class="modal-body">
            <div class="text-xxl text-center margin-top">
              <img src="@/assets/img/tip.jpg" class="tshi" />
            </div>
            <p
              class="text-xl text-center margin-top"
              style="color: #000000; text-align: center; text-indent: 0px"
              v-text="msgs"
            ></p>
            <div class="text-xl text-center margin-top margin-bottom">
              <button
                class="cu-btn round bg-gradual-green btn ok"
                data-dismiss="modal"
                style="
                  background-image: linear-gradient(45deg, #72b588, #6e9c7d);
                  border-radius: 16px;
                "
                @click="masks"
              >
                我知道了
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-backdrop fade in" v-show="deng"></div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      cellPhone: "",
      password: "",
      newPassword: "",
      code: "",
      yzm: "",
      yzm1: "",
      // 验证
      cellPhone1: "",
      password1: "",
      newPassword1: "",
      code1: "11",
      msgs: "",
      deng: false,
      time: 60,
      num: 60,
    };
  },

  methods: {
    masks() {
      this.deng = false;
    },
    models() {
      var namess = /^((13[0-9])|(14[0-9])|(15([0-9]))|(16([0-9]))|(17([0-9]))|(18[0-9])|(19[0-9]))\d{8}$/;
      if (this.cellPhone == "") {
        this.msgs = "请填写手机号";
      } else if (namess.test(this.cellPhone)) {
        this.cellPhone1 = "手机号正确";
      } else {
        this.msgs = "手机格式不正确";
      }
    },
    inpt() {
      if (this.password == "") {
        this.msgs = "密码不能为空";
      } else if (this.password) {
        this.password1 = "密码正确";
      }
    },
    inpts() {
      if (this.newPassword == "") {
        this.msgs = "密码不能为空";
      } else if (this.newPassword == this.password) {
        this.newPassword1 = "密码正确";
      } else {
        this.msgs = "两次密码不一致";
      }
    },
    codes(e) {
      console.log(e, this.code);
    },
    btn() {
      if (this.cellPhone == "") {
        this.msgs = "手机号不能为空";
        this.deng = true;
        return;
      }
      if (this.password == "") {
        this.msgs = "密码不能为空";
        this.deng = true;
        return;
      }
      if (this.newPassword == "") {
        this.msgs = "确认密码不能为空";
        this.deng = true;
        return;
      }
      if (this.newPassword != this.password) {
        this.msgs = "前后密码不一致";
        this.deng = true;
        return;
      }
      if (this.code == "") {
        this.msgs = "手机验证码不能为空";
        this.deng = true;
        return;
      }

      let loginParam = {
        userPhone: this.cellPhone,
        userPwd: this.password,
        code: this.code,
      };
      this.$axios({
        url:"bm/pcRegister",
        method:"post",
        data:loginParam
      })
          .then((res) => {
            if (res.data.code == "200") {
              setTimeout(() => {
                this.deng = false;
                this.$router.replace({
                  path: "/src/views/signUp.vue",
                });
              }, 2000);
              this.msgs = "注册成功";
              this.deng = true;
            } else {
              this.msgs = res.data.msg;
              this.deng = true;

              // setTimeout(() => {
              //   this.deng = false;
              //   this.$router.replace({
              //     path: "/src/views/signUp.vue",
              //   });
              // }, 2000);
            }
          })
          .catch((err) => {
            console.log(err);
          });

    },
    goes() {
      if (this.cellPhone1 == "手机号正确") {
        var that = this;
        if (that.time !== 0) {
          var count = setInterval(function () {
            var newtime = that.time--;
            that.num = newtime;

            // that.data.time=time1
            if (that.time === 0) {
              that.time = 60;
              that.num = 60;
              clearInterval(count);
            }
          }, 1000);
        }
        if(that.time==60){
            this.$axios({
              url:"bm/sendAliyunSms",
              method:"get",
              params: {
                data: { userPhone: that.cellPhone },
              },
            })
            .then((res) => {
              if (res.data.code == "200") {
                // console.log(res, "qqqqqqq");
                that.code1 = res.data.data;
              }
            });

          }
      } else {
        var namess =
          /^((13[0-9])|(14[0-9])|(15([0-9]))|(16([0-9]))|(17([0-9]))|(18[0-9])|(19[0-9]))\d{8}$/;
        if (this.cellPhone == "") {
          this.msgs = "请填写手机号";
          this.deng = true;
        } else if (namess.test(this.cellPhone)) {
          this.cellPhone1 = "手机号正确";
          this.msgs = "短信已发送";
          this.deng = true;
        } else {
          this.msgs = "手机格式不正确";
          this.deng = true;
        }
      }
    },
  },
};
</script>
<style></style>
