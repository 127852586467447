<template>
  <el-dialog
    title="审核未通过"
    :show-close="false"
    :visible.sync="detailObj.visible"
    class="success_dialog"
  >
    <div>
      <p>{{detailObj.msg}}</p>
      <div class="show_detail_button_div">
        <div class="button_111" @click="handelSubmit()">我知道了</div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
export default {
  props: {
    detailObj: {
      type: Object,
    },
  },
  data() {
    return {};
  },
  methods: {
    // 蒙版
    handleClose() {
      this.detailObj.visible = false;
    },
    handelSubmit(){
        this.detailObj.visible = false;
    }
    
  },
};
</script>

<style lang="scss">
.success_dialog {
  > div {
    display: flex; /* 将容器设置为 Flex 容器 */
    justify-content: center; /* 水平居中 */
    align-items: center; /* 垂直居中 */
  }
  .show_detail_button_div{
    margin:25px auto 0;
  }
  img {
    width: 102px;
    margin-top: 20px;
  }
  p {
    text-indent: 0px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 14px;
    color: #444444;
    line-height: 24px;
    text-align: left;
    font-style: normal;
    margin-top: 15px;
  }
  .button_111 {
    width: 114px;
    height: 38px;
    background: #81baab;
    border-radius: 4px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 600;
    font-size: 16px;
    color: #ffffff;
    line-height: 38px;
    text-align: center;
    cursor: pointer;
    margin:0 auto;
  }
  .button_2 {
    width: 80px;
    height: 38px;
    border-radius: 4px;
    border: 2px solid #81baab;
    font-family: PingFangSC, PingFang SC;
    font-weight: 600;
    font-size: 16px;
    color: #81baab;
    line-height: 32px;
    text-align: center;
    font-style: normal;
    box-sizing: border-box;
    cursor: pointer;
  }
}
.success_dialog .el-dialog {
  transform: none;
  left: 0 !important;
  position: relative !important;
  margin: 0 auto !important;
}
.success_dialog .el-dialog .el-dialog__body {
  overflow-y: auto !important;
  overflow-x: hidden !important;
}
.success_dialog .el-dialog__footer {
  width: auto !important;
}
.success_dialog .el-dialog {
  border-radius: 16px;
  display: flex !important;
  flex-direction: column !important;
  margin: 0 !important;
  position: absolute !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
  width: 342px;
  height: 222px;
}
.success_dialog .el-dialog .el-dialog__body {
  padding: 10px 30px !important;
  flex: 1;
  overflow: auto;
}
.success_dialog .el-dialog__header {
  text-align: center;
  width: 100%;
  border-bottom: 1px solid rgba(150, 157, 171, 0.3);
}
.success_dialog .el-dialog__headerbtn .el-dialog__close {
  font-size: 25px !important;
  font-weight: bolder;
}
.success_dialog .el-dialog__title {
  font-weight: 600;
  font-size: 22px !important;
}
</style>
