<template>
  <div v-show="validCheck">
    <headers></headers>
    <div class="bmbg">
      <div class="main1200 shadow-warp radius" style="">
        <div class="text-bold text-sl margin-xl">报名信息修改</div>

        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="145.59px"
          label-position="left"
          class="demo-ruleForm"
          style="background-color: #fff"
        >
          <div class="normal_div">作品信息</div>
          <div class="normal_count">
            <el-row :gutter="80">
              <el-col :span="24">
                <el-form-item label="创作村庄" prop="city">
                  <el-select
                    v-model="ruleForm.city"
                    placeholder="请选择城市"
                    style="width: 209px; margin-right: 10px"
                    @change="selectGet"
                  >
                    <el-option
                      :label="item.name"
                      :value="item.id"
                      v-for="(item, index) in cityLists"
                      :key="index"
                    >
                    </el-option>
                  </el-select>
                  <el-select
                    v-model="ruleForm.town"
                    placeholder="请选择县区"
                    style="width: 209px; margin-right: 10px"
                    @change="selectGets"
                  >
                    <el-option
                      :label="item.name"
                      :value="item.id"
                      v-for="(item, index) in xianqu"
                      :key="index"
                    ></el-option>
                  </el-select>
                  <el-select
                    v-model="ruleForm.village"
                    placeholder="请选择村庄"
                    style="width: 543px"
                    @change="selectGet2"
                  >
                    <el-option
                      :label="item.name"
                      :value="item.id"
                      v-for="(item, index) in cunzhaung"
                      :key="index"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="作品形式" prop="zpxshi">
                  <el-radio-group
                    style="width: 100%"
                    v-model="ruleForm.zpxshi"
                    @change="resourceChange"
                  >
                    <el-radio :label="1">单幅</el-radio>
                    <el-radio :label="2">组幅</el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="作品类型" prop="zplxing">
                  <el-select
                    v-model="ruleForm.zplxing"
                    placeholder="请选择作品类型"
                    style="width: 100%"
                  >
                    <el-option
                      :label="item.name"
                      :value="item.id"
                      v-for="(item, index) in zuoPinLeiXingLists"
                      :key="index"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="指导老师姓名">
                  <el-input
                    v-model="ruleForm.zdlshi"
                    style="width: 100%"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="作品名称" prop="zpmcheng">
                  <el-input
                    v-model="ruleForm.zpmcheng"
                    style="width: 100%"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item prop="zpjjie">
                  <span slot="label"
                    >作品简介
                    <p class="label_p">（200字以内）</p>
                  </span>
                  <el-input
                    :rows="3"
                    type="textarea"
                    v-model="ruleForm.zpjjie"
                    style="width: 100%"
                    maxlength="200"
                    show-word-limit
                  >
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item label="作品电子版上传" prop="dzban">
                  <el-row>
                    <el-col :span="24">
                      <el-upload
                        :class="{ hide_upload: hideUploadEdit1 }"
                        id="photo1"
                        :action="artWorkUploadUrl"
                        list-type="picture-card"
                        :accept="uploadAccept"
                        :before-upload="handleBeforeUploadDzb"
                        :on-preview="handlePictureCardPreview"
                        :on-remove="handleRemove"
                        :on-success="handLsucees"
                        :on-change="handleEditChange1"
                        :limit="1"
                        :file-list="fileList"
                      >
                        <i class="el-icon-plus"></i>
                      </el-upload>
                      <el-dialog
                        class="show_pic_dia"
                        :visible.sync="dialogVisible"
                      >
                        <img
                          width="100%"
                          :src="dialogImageUrl"
                          alt=""
                          style="z-index: 3000"
                        />
                      </el-dialog>
                      （单次报名仅可上传一幅作品，若作品为组画，需合并成一张图片后上传，图片格式支持jpg/jpeg/png，图片大小要求大于2M且小于10M，否则无法成功上传)
                    </el-col>
                  </el-row>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item
                  label="作者描绘场景或人物实景照片上传"
                  prop="zpcjing"
                >
                  <div class="upload_div">
                    <el-upload
                      :class="{ hide_upload2: hideUploadEdit2 }"
                      id="photo4"
                      :action="uploadTmpUrl"
                      :accept="uploadAccept"
                      list-type="picture-card"
                      :before-upload="handleBeforeUpload"
                      :on-preview="handlePictureCardPreview2"
                      :on-remove="handleRemove2"
                      :on-success="handLsucees2"
                      :on-change="handleEditChange2"
                      :limit="5"
                      :file-list="fileList2"
                    >
                      <i class="el-icon-plus"></i>
                    </el-upload>
                    <el-dialog
                      class="show_pic_dia"
                      :visible.sync="dialogVisible2"
                    >
                      <img width="100%" :src="dialogImageUrl2" alt="" />
                    </el-dialog>
                  </div>
                  <p>
                    (最多上传5张照片，图片格式支持jpg/jpeg/png，单张图片大小要求小于1M，否则无法成功上传)
                  </p>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item
                  label="作者在村庄现场采风创作的佐证照片上传"
                  id="photo7"
                  prop="xczhua"
                >
                  <div class="upload_div">
                    <el-upload
                      :class="{ hide_upload3: hideUploadEdit3 }"
                      :action="uploadTmpUrl"
                      :accept="uploadAccept"
                      list-type="picture-card"
                      :before-upload="handleBeforeUpload"
                      :on-preview="handlePictureCardPreview3"
                      :on-remove="handleRemove3"
                      :on-success="handLsucees3"
                      :on-change="handleEditChange3"
                      :limit="5"
                      :file-list="fileList3"
                    >
                      <i class="el-icon-plus"></i>
                    </el-upload>
                    <el-dialog
                      class="show_pic_dia"
                      :visible.sync="dialogVisible3"
                    >
                      <img width="100%" :src="dialogImageUrl3" alt="" />
                    </el-dialog>
                  </div>
                  <p>
                    (最多上传5张照片，图片格式支持jpg/jpeg/png，单张图片大小要求小于1M，否则无法成功上传)
                  </p>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item label="指导老师指导作者创作照片上传">
                  <div class="upload_div">
                    <el-upload
                      :class="{ hide_upload4: hideUploadEdit4 }"
                      id="photo10"
                      :accept="uploadAccept"
                      :action="uploadTmpUrl"
                      list-type="picture-card"
                      :before-upload="handleBeforeUpload"
                      :on-preview="handlePictureCardPreview4"
                      :on-remove="handleRemove4"
                      :on-success="handLsucees4"
                      :on-change="handleEditChange4"
                      :limit="5"
                      :file-list="fileList4"
                    >
                      <i class="el-icon-plus"></i>
                    </el-upload>
                    <el-dialog
                      class="show_pic_dia"
                      :visible.sync="dialogVisible4"
                    >
                      <img width="50%" :src="dialogImageUrl4" alt="" />
                    </el-dialog>
                  </div>
                  <p>
                    (最多上传5张照片，图片格式支持jpg/jpeg/png，单张图片大小要求小于1M，否则无法成功上传)
                  </p>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="作品选送单位">
                  <el-select
                    filterable
                    clearable
                    v-model="ruleForm.danwei"
                    placeholder="请选择单位"
                    @change="handleSelects"
                    style="width: 100%"
                  >
                    <el-option
                      v-for="item in restaurant"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                    >
                    </el-option>
                  </el-select>
                  <br />
                  <font
                    style="
                      font-size: 14px;
                      font-weight: bold;
                      white-space: nowrap;
                    "
                    >（选填项，只有经各设区市住建局选送的作品才需填写，个人直接投稿作品请勿填写）</font
                  >
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item label="承诺书" :required="true">
                  本人承诺参加第三届“丹青妙笔绘田园乡村”活动的绘画作品为本人原创，不存在抄袭或他人执笔等虚假行为；本人填写的各项报名信息全部真实有效，并严格遵守活动各项规则要求。如因本人提供虚假信息或违反活动规则等，本人愿承担一切责任及后果。
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item label-width="0px">
                  <div class="row bmrow">
                    <div class="col-md-12">
                      <div class="qmkuang padding-sm">
                        <div class="qqm">
                          承诺人签名<span
                            class="padding-left-sm text-gray text-df padding-top-sm"
                            >(拖动鼠标签名)</span
                          >
                        </div>
                        <div id="signature-pad" class="signature-pad">
                          <vue-esign
                            ref="esign"
                            :width="1100"
                            :height="300"
                            :line-width="8"
                            line-color="#000"
                            bg-color="#FFF"
                          />
                        </div>
                        <input
                          type="hidden"
                          id="qianming"
                          name="qianming"
                          value=""
                        />
                        <input
                          type="hidden"
                          id="user"
                          name="user"
                          value="18851113103"
                        />
                        <input
                          type="hidden"
                          id="level"
                          name="level"
                          value="1"
                        />
                        <input type="hidden" id="id" name="id" value="" />
                        <input type="hidden" id="type" name="type" value="" />
                      </div>
                    </div>
                  </div>
                </el-form-item>
              </el-col>
              <el-col :span="20" style="margin-left: 15px; margin-right: 15px">
                <el-form-item label-width="0px">
                  <div class="col-md-6">
                    <div class="layui-row">
                      <el-checkbox v-model="checked" @change="cheskop"
                        >同意活动 </el-checkbox
                      ><span class="text-blue" @click="chank"
                        >《隐私政策》</span
                      >
                    </div>
                  </div>
                </el-form-item>
              </el-col>
              <el-col :span="23" style="margin-left: 15px; margin-right: 15px">
                <el-form-item label-width="0px" style="text-align: center">
                  <div>
                    <el-button class="new_but" @click="handleReset"
                      >重新签名</el-button
                    >
                    <el-button @click="handleGenerate">确定签名</el-button>
                    <!-- <el-button @click="getform(1)">暂存</el-button> -->
                    <el-button class="submit_but" @click="getform(2)"
                      >提交报名</el-button
                    >
                  </div>
                </el-form-item>
              </el-col>
            </el-row>
          </div>
        </el-form>
      </div>
    </div>
    <footers></footers>
    <tips :deng="deng" @takeTips="takeTips" :tipsTitle="tipsTitle" />
    <privaceDialog :obj="centerDialogObj" />
    <fileTips :fileTipsObj="fileTipsObj" />
    <submitDialog :submitObj="submitObj" @getSubmit="getSubmit" />
  </div>
</template>
<script>
import submitDialog from "@/components/submitDialog";
import fileTips from "@/components/fileTips.vue";
import tips from "@/components/tips.vue";
import privaceDialog from "@/components/privaceDialog.vue";
import headers from "@/components/headers.vue";
import footers from "@/components/footers.vue";
import { getCookie, setCookie, getValidUser } from "./utils";
import utilMd5 from "@/assets/md5";
import RequestApi from "@/api";

export default {
  data() {
    var validatorPhone = function (rule, value, callback) {
      if (value === "") {
        callback(new Error("手机号不能为空"));
      } else if (!/^1\d{10}$/.test(value)) {
        callback(new Error("手机号格式错误"));
      } else {
        callback();
      }
    };
    return {
      submitObj: {
        visible: false,
      },
      fileTipsObj: {
        visible: false,
        msg: "",
      },
      guoJiLists: [],
      uploadAccept: ".jpg,.jpeg,.png",
      // 上传地址
      uploadTmpUrl: RequestApi.ReqUrl + "upload/image",
      // 电子作品上传地址
      artWorkUploadUrl: RequestApi.ReqUrl + "signup/apply/upload/art",

      validCheck: false, //当前用户是否登录有效

      centerDialogObj: {
        visible: false,
      },
      resultImg: "", // base64图片
      msgs: "",
      deng: false,
      tipsTitle: "",
      dialogImageUrl: "",
      dialogImageUrl2: "",
      dialogImageUrl3: "",
      dialogImageUrl4: "",
      dialogVisible: false,
      dialogVisible2: false,
      dialogVisible3: false,
      dialogVisible4: false,
      ruleForm: {},
      rules: {
        zpmcheng: [
          { required: true, message: "请输入作品名称", trigger: "blur" },
        ],
        zpjjie: [
          { required: true, message: "请输入作品简介", trigger: "blur" },
        ],
        city: [
          { required: true, message: "请选择创作村庄信息", trigger: "blur" },
        ],
        zpxshi: [
          { required: true, message: "请选择作品形式", trigger: "blur" },
        ],
        zplxing: [
          { required: true, message: "请选择作品类型", trigger: "blur" },
        ],
        dzban: [
          { required: true, message: "请上传作品电子版", trigger: "blur" },
        ],
        zpcjing: [
          {
            required: true,
            message: "请上传作者描绘场景或人物实景照片",
            trigger: "blur",
          },
        ],
        xczhua: [
          {
            required: true,
            message: "请上传作者在村庄现场作画的佐证照片",
            trigger: "blur",
          },
        ],
      },
      restaurant: [],
      // 所在地
      cityLists: {},
      // 作品送选单位
      xuanSongDanWeiLists: {},
      // 作品类型
      zuoPinLeiXingLists: {},
      // 作品形式
      zuoPinXingShiLists: {},
      // 城市ID
      cityId: "",
      // 县区ID
      xianquID: "",
      // 村庄ID
      cunzhuangID: "",
      // 县区
      xianqu: {},
      // 村庄
      cunzhaung: {},
      // 添加
      photo1: [],
      fileList: [],
      photos2: [],
      fileList2: [],
      photos3: [],
      fileList3: [],
      photos4: [],
      fileList4: [],
      checked: false,
      hideUploadEdit1: false,
      hideUploadEdit2: false,
      hideUploadEdit3: false,
      hideUploadEdit4: false,
      zpIndex:null
    };
  },
  components: {
    headers,
    footers,
    privaceDialog,
    tips,
    fileTips,
    submitDialog,
    "el-image-viewer": () =>
      import("element-ui/packages/image/src/image-viewer"), // 预览签名图片的插件，无需下载，elementui自带
  },
  created() {
    let user = getValidUser();
    // if (user == null) {
    //   window.location.href = "/src/views/signUp.vue";
    //   return;
    // }

    this.validCheck = true;
  },
  mounted() {
    this.image = "";
    this.mousePressed = false;

    this.xianquList();
    this.cunzhuangList();
    this.allList();
    this.dataEcho();
  },
  methods: {
    dataEcho() {
      // console.log('1111111111111',this.$route.query.zpIndex)
      this.zpIndex = this.$route.query.zpIndex;
      const loginUser = sessionStorage.getItem("user");
      if (!loginUser || loginUser == "") {
        this.msgs = "请登录后进行报名信息填写！";
        this.deng = true;
        return;
      }
      this.$axios({
        url: "signup/apply/Detail",
        method: "get",
        params: {
          user: loginUser,
        },
      }).then((res) => {
        if (res.data.code == "200") {
          let obj = res.data.data;
          let infObj = obj.info;
          let artworks = obj.artworks[this.zpIndex];
          let str = JSON.stringify(artworks);
          //依次修改对象中的每一个对象
          str = str.replace("id", "zuoPinId");
          artworks = JSON.parse(str);
          this.ruleForm = {
            ...infObj,
            ...artworks,
          };
          if (this.ruleForm.city != null) {
            this.cityId = this.ruleForm.city;
            this.xianquList();
          }
          if (this.ruleForm.town != null) {
            this.xianquID = this.ruleForm.town;
            this.cunzhuangList();
          }
          if (this.ruleForm.dzban != null && this.ruleForm.dzban != "") {
            //作品电子版上传图片回显处理
            this.fileList = [
              {
                url: RequestApi.StaticPath + this.ruleForm.dzban,
                response: {
                  data: this.ruleForm.dzban,
                },
              },
            ];
            this.photo1.push(this.ruleForm.dzban);
            this.hideUploadEdit1 = this.photo1.length == 1;
          }
          if (this.ruleForm.zpcjing != null && this.ruleForm.zpcjing != "") {
            //作者描绘场景或人物实景照片回显处理
            let data = this.ruleForm.zpcjing.split(",");
            data.forEach((item) => {
              let obj = {
                url: RequestApi.StaticPath + item,
                response: {
                  data: [item],
                },
              };
              this.fileList2.push(obj);
              this.photos2.push(item);
            });
            this.hideUploadEdit2 = this.photos2.length == 5;
          }
          if (this.ruleForm.xczhua != null && this.ruleForm.xczhua != "") {
            //作者在村庄现场采风创作的佐证照片上回显处理
            let data = this.ruleForm.xczhua.split(",");
            data.forEach((item) => {
              let obj = {
                url: RequestApi.StaticPath + item,
                response: {
                  data: [item],
                },
              };
              this.fileList3.push(obj);
              this.photos3.push(item);
            });
            this.hideUploadEdit3 = this.photos3.length == 5;
          }
          if (this.ruleForm.lszdao != null && this.ruleForm.lszdao != "") {
            //指导老师指导作者创作照片回显处理
            let data = this.ruleForm.lszdao.split(",");
            data.forEach((item) => {
              let obj = {
                url: RequestApi.StaticPath + item,
                response: {
                  data: [item],
                },
              };
              this.fileList4.push(obj);
              this.photos4.push(item);
            });
            this.hideUploadEdit4 = this.photos4.length == 5;
          }

          if (this.ruleForm.xieyi != null) {
            if (this.ruleForm.xieyi === 1) {
              this.checked = true;
            } else if (this.ruleForm.xieyi === 0) {
              this.checked = false;
            }
          }
        } else {
          this.fileTipsObj.visible = true;
          this.fileTipsObj.msg = res.data.msg;
        }
      });
    },

    hasaccept(type) {
      let arr = this.uploadAccept.split(",");
      let flag = false;
      for (let string of arr) {
        if (!flag) {
          flag = string.toLowerCase() == type.toLowerCase();
        }
      }
      return flag;
    },
    // 下拉列表
    allList() {
      this.$axios({
        url: "bm/getBaoMingInit",
        method: "get",
        params: {
          data: {},
        },
      })
        .then((res) => {
          // 所在地
          this.cityLists = res.data.data.cityList;
          // 作品送选单位
          this.restaurant = res.data.data.xuanSongDanWeiList;
          // 作品类型
          this.zuoPinLeiXingLists = res.data.data.zuoPinLeiXingList;
          // 作品形式
          this.zuoPinXingShiLists = res.data.data.zuoPinXingShiList;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    takeTips() {
      this.deng = false;
    },
    cheskop(e) {
      this.checked = e;
      this.ruleForm.xieyi = e ? 1 : 0;
    },
    chank() {
      this.centerDialogObj.visible = true;
    },
    resourceChange(e) {
      this.ruleForm.resource = e;
    },
    handleReset() {
      this.$refs["esign"].reset(); // 清空画布
      this.resultImg = null;
      this.ruleForm.qianming = null;
    },
    handleGenerate() {
      this.$refs["esign"]
        .generate()
        .then((res) => {
          this.resultImg = res; // 得到了签字生成的base64图片
          this.ruleForm.qianming = res;
          console.log(this.resultImg);
          this.fileTipsObj.visible = true;
          this.fileTipsObj.msg = "签名完成";
        })
        .catch(() => {
          // 没有签名，点击生成图片时调用
          this.$message({
            message: "未签名!",
            type: "warning",
          });
        });
    },
    getSubmit() {
      this.submitObj.visible = true;
    },
    getform(ee) {
      const loginUser = sessionStorage.getItem("user");
      this.ruleForm.user = loginUser;
      this.ruleForm.dzban = this.photo1.join(",");
      this.ruleForm.zpcjing = this.photos2.join(",");
      this.ruleForm.xczhua = this.photos3.join(",");
      this.ruleForm.lszdao = this.photos4.join(",");
      if (ee == 1) {
        this.$axios({
          url: "signup/apply/second/temporary",
          method: "post",
          data: this.ruleForm,
        })
          .then((res) => {
            if (res.data.code != 200) {
              this.fileTipsObj.visible = true;
              this.fileTipsObj.msg = res.data.msg;
              return;
            }
            this.ruleForm.id = res.data.data.id;
            this.ruleForm.zuoPinId = res.data.data.zuoPinId;

            this.deng = true;
            this.tipsTitle = "暂存成功";
          })
          .catch((err) => {
            console.error(err);
          });
      } else {
        this.verifyForm()
        // this.submitObj.visible = true;
      }
    },
    getSubmit() {
      this.$axios({
        url: this.zpIndex == 0 ? 'signup/apply/first' : 'signup/apply/second',
        method: "post",
        data: this.ruleForm,
      })
        .then((res) => {
          if (res.data.code != 200) {
            this.fileTipsObj.visible = true;
            this.fileTipsObj.msg = res.data.msg;
            return;
          }
          this.deng = true;
          this.tipsTitle = "提交成功";
        })
        .catch((err) => {
          console.error(err);
        });
    },
    verifyForm() {
      if (
        this.ruleForm.city == "" ||
        !this.ruleForm.city ||
        this.ruleForm.city == null
      ) {
        this.fileTipsObj.visible = true;
        this.fileTipsObj.msg = "请选择创作村庄";
        return false;
      }
      if (
        this.ruleForm.town == "" ||
        !this.ruleForm.town ||
        this.ruleForm.town == null
      ) {
        this.fileTipsObj.visible = true;
        this.fileTipsObj.msg = "请选择创作村庄";
        return false;
      }
      if (
        this.ruleForm.village == "" ||
        !this.ruleForm.village ||
        this.ruleForm.village == null
      ) {
        this.fileTipsObj.visible = true;
        this.fileTipsObj.msg = "请选择创作村庄";
        return false;
      }
      if (
        this.ruleForm.zpxshi == "" ||
        !this.ruleForm.zpxshi ||
        this.ruleForm.zpxshi == null
      ) {
        this.fileTipsObj.visible = true;
        this.fileTipsObj.msg = "请勾选作品形式";
        return false;
      }
      if (
        this.ruleForm.zplxing == "" ||
        !this.ruleForm.zplxing ||
        this.ruleForm.zplxing == null
      ) {
        this.fileTipsObj.visible = true;
        this.fileTipsObj.msg = "请选择作品类型";
        return false;
      }
      if (
        this.ruleForm.zpmcheng == "" ||
        !this.ruleForm.zpmcheng ||
        this.ruleForm.zpmcheng == null
      ) {
        this.fileTipsObj.visible = true;
        this.fileTipsObj.msg = "请输入作品名称";
        return false;
      }
      if (
        this.ruleForm.zpjjie == "" ||
        !this.ruleForm.zpjjie ||
        this.ruleForm.zpjjie == null
      ) {
        this.fileTipsObj.visible = true;
        this.fileTipsObj.msg = "请输入作品简介";
        return false;
      }

      if (
        this.ruleForm.dzban == "" ||
        !this.ruleForm.dzban ||
        this.ruleForm.dzban == null ||
        this.ruleForm.dzban.length == 0
      ) {
        this.fileTipsObj.visible = true;
        this.fileTipsObj.msg = "请上传作品电子版";
        return false;
      }
      if (
        this.ruleForm.zpcjing == "" ||
        !this.ruleForm.zpcjing ||
        this.ruleForm.zpcjing == null ||
        this.ruleForm.zpcjing.length == 0
      ) {
        this.fileTipsObj.visible = true;
        this.fileTipsObj.msg = "请上传作者描绘场景或人物实景照片";
        return false;
      }
      if (
        this.ruleForm.xczhua == "" ||
        !this.ruleForm.xczhua ||
        this.ruleForm.xczhua == null ||
        this.ruleForm.xczhua.length == 0
      ) {
        this.fileTipsObj.visible = true;
        this.fileTipsObj.msg = "请上传作者在村庄现场采风创作的佐证照片";
        return false;
      }
      if (
        this.ruleForm.qianming == "" ||
        !this.ruleForm.qianming ||
        this.ruleForm.qianming == null
      ) {
        this.fileTipsObj.visible = true;
        this.fileTipsObj.msg = "请确认签名";
        return false;
      }
      if (
        this.ruleForm.xieyi == "" ||
        !this.ruleForm.xieyi ||
        this.ruleForm.xieyi == null ||
        this.ruleForm.xieyi == 0
      ) {
        this.fileTipsObj.visible = true;
        this.fileTipsObj.msg = "请勾选同意活动";
        return false;
      }
      this.submitObj.visible = true;
    },
    handleEditChange1(file, fileList) {
      this.hideUploadEdit1 = fileList.length == 1;
    },
    handleEditChange2(file, fileList) {
      this.hideUploadEdit2 = fileList.length == 5;
    },
    handleEditChange3(file, fileList) {
      this.hideUploadEdit3 = fileList.length == 5;
    },
    handleEditChange4(file, fileList) {
      this.hideUploadEdit4 = fileList.length == 5;
    },
    handLsucees(response, file, fileList) {
      if (response.code != 200) {
        this.fileTipsObj.visible = true;
        this.fileTipsObj.msg = response.msg;
        return;
      }
      this.photo1.push(response.data);
    },
    handLsucees2(response, file, fileList) {
      if (response.code != 200) {
        this.fileTipsObj.visible = true;
        this.fileTipsObj.msg = response.msg;
        return;
      }
      this.photos2.push(response.data[0]);
    },
    handLsucees3(response, file, fileList) {
      if (response.code != 200) {
        this.fileTipsObj.visible = true;
        this.fileTipsObj.msg = response.msg;
        return;
      }
      this.photos3.push(response.data[0]);
    },
    handLsucees4(response, file, fileList) {
      console.log(response);
      if (response.code != 200) {
        this.fileTipsObj.visible = true;
        this.fileTipsObj.msg = response.msg;
        return;
      }
      this.photos4.push(response.data[0]);
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
      for (let i = 0; i < this.photo1.length; i++) {
        if (this.photo1[i] == file.response.data) {
          this.photo1.splice(i, 1);
          break;
        }
      }
      this.hideUploadEdit1 = fileList.length == 1;
    },
    handleRemove2(file, fileList) {
      console.log(file, fileList);
      for (let i = 0; i < this.photos2.length; i++) {
        if (this.photos2[i] == file.response.data[0]) {
          this.photos2.splice(i, 1);
          break;
        }
      }
      this.hideUploadEdit2 = fileList.length == 5;
    },
    handleRemove3(file, fileList) {
      for (let i = 0; i < this.photos3.length; i++) {
        if (this.photos3[i] == file.response.data[0]) {
          this.photos3.splice(i, 1);
          break;
        }
      }
      this.hideUploadEdit3 = fileList.length == 5;
    },
    handleRemove4(file, fileList) {
      console.log(file);
      for (let i = 0; i < this.photos4.length; i++) {
        if (this.photos4[i] == file.response.data[0]) {
          this.photos4.splice(i, 1);
          break;
        }
      }
      this.hideUploadEdit4 = fileList.length == 5;
    },
    handleBeforeUploadDzb(file) {
      if (!this.hasaccept(file.name.substring(file.name.lastIndexOf(".")))) {
        this.fileTipsObj.msg = "作品电子版上传图片只支持jpg,jpeg,png格式";
        this.fileTipsObj.visible = true;
        return false;
      }
      // 电子版图片文件大小
      const isLt2M = file.size / 1024 / 1024 < 2;
      const isLt2M2 = file.size / 1024 / 1024 > 10;
      if (isLt2M || isLt2M2) {
        this.fileTipsObj.msg = "作品电子版上传图片必须大于2M且小于10M";
        this.fileTipsObj.visible = true;
        return false;
      }

      return true;
    },
    handleBeforeUpload(file) {
      if (!this.hasaccept(file.name.substring(file.name.lastIndexOf(".")))) {
        this.fileTipsObj.msg = "作品电子版上传图片只支持jpg,jpeg,png格式";
        this.fileTipsObj.visible = true;
        return false;
      }
      // 电子版图片文件大小
      if (file.size / 1024 / 1024 > 1) {
        this.fileTipsObj.msg = "单张图片必须小于1M";
        this.fileTipsObj.visible = true;
        return false;
      }
      return true;
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    handlePictureCardPreview2(file) {
      this.dialogImageUrl2 = file.url;
      this.dialogVisible2 = true;
    },
    handlePictureCardPreview3(file) {
      this.dialogImageUrl3 = file.url;
      this.dialogVisible3 = true;
    },
    handlePictureCardPreview4(file) {
      this.dialogImageUrl4 = file.url;
      this.dialogVisible4 = true;
    },
    createFilter(queryString) {
      return (restaurant) => {
        return (
          restaurant.name.toLowerCase().indexOf(queryString.toLowerCase()) === 0
        );
      };
    },
    handleSelects(items) {
      console.log(items);
      if (items != "") {
        let cobjse = {};
        cobjse = this.restaurant.find((item) => {
          //这⾥的selectList就是上⾯遍历的数据源
          return item.id === items; //筛选出匹配数据
        });
        this.ruleForm.danwei = cobjse.id;
      } else {
        this.ruleForm.danwei = "";
      }

      this.$forceUpdate();
    },
    //
    selectGet(vId) {
      let obj = {};
      obj = this.cityLists.find((item) => {
        //这⾥的selectList就是上⾯遍历的数据源
        return item.id === vId; //筛选出匹配数据
      });
      this.cityId = obj.id;
      this.xianquList();
      this.$forceUpdate();
      this.ruleForm.town = "";
      this.ruleForm.village = "";
      // setCookie("time", new Date().getTime(), -1);
    },
    selectGet3(vId) {
      let obj = {};
      obj = this.cityLists.find((item) => {
        //这⾥的selectList就是上⾯遍历的数据源
        return item.id === vId; //筛选出匹配数据
      });
      this.cityIds = obj.id;
      this.$forceUpdate();
      // setCookie("time", new Date().getTime(), -1);
    },
    selectGets(cId) {
      let objs = {};
      objs = this.xianqu.find((item) => {
        //这⾥的selectList就是上⾯遍历的数据源
        return item.id === cId; //筛选出匹配数据
      });
      this.xianquID = objs.id;
      this.ruleForm.village = "";
      this.cunzhuangList();
      this.$forceUpdate();
      // setCookie("time", new Date().getTime(), -1);
    },
    selectGet2(cIds) {
      let objes = {};
      objes = this.cunzhaung.find((item) => {
        //这⾥的selectList就是上⾯遍历的数据源
        return item.id === cIds; //筛选出匹配数据
      });
      this.cunzhuangID = objes.id;
      this.$forceUpdate();
    },
    // 县区下拉列表
    xianquList() {
      this.$axios({
        url: "bm/getQxListByCityId",
        method: "get",
        params: {
          data: { cityId: this.cityId },
        },
      })
        .then((res) => {
          // console.log(res, "hahhaha ");
          // console.log(res.data.data, "aaa");
          // 县区
          this.xianqu = res.data.data;
          this.$forceUpdate();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 村庄下拉列表
    cunzhuangList() {
      this.$axios({
        url: "bm/getVillageListByQxId",
        method: "get",
        params: {
          data: { qxId: this.xianquID },
        },
      })
        .then((res) => {
          // console.log(res, "hahhaha ");
          // 村庄
          this.cunzhaung = res.data.data;
          this.$forceUpdate();
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
<style lang="scss">
// // 隐藏上传按钮
.hide_upload .el-upload--picture-card {
  display: none;
}
.hide_upload2 .el-upload--picture-card {
  display: none;
}
.hide_upload3 .el-upload--picture-card {
  display: none;
}
.hide_upload4 .el-upload--picture-card {
  display: none;
}
.show_pic_dia .el-dialog {
  width: 600px;
  min-height: 600px;
  img {
    width: 100%;
  }
}
</style>
<style lang="scss" scoped>
.submit_but {
  background: #81baab;
  border-radius: 4px;
  color: #ffffff;
}
.submit_but:hover {
  background: #f0faf7;
  border-radius: 4px;
  border: 1px solid #81baab;
  color: #81baab;
}
.new_but:hover {
  background: #81baab;
  border-radius: 4px;
  color: #ffffff;
}
.new_but {
  background: #f0faf7;
  border-radius: 4px;
  border: 1px solid #81baab;
  color: #81baab;
}
.text-bold {
  padding: 10px 0 0 15px;
}
.main1200 {
  padding: 0px;
}
.label_p {
  margin-top: -10px;
  text-indent: 0px;
  margin-bottom: 0px;
  line-height: 18px;
  white-space: nowrap;
}
.zc_but {
  margin: 10px 0 30px 550px;
}
.slot_span {
  position: relative;
}
.label_span {
  display: block;
  text-align: left !important;
  line-height: 15px !important;
  text-align: left;
  margin-top: -11px;
}
.normal_count {
  width: 94%;
  margin: 0 auto;
}
.normal_div {
  background-color: #f5f5f5;
  padding: 15px 40px;
  font-family: MicrosoftYaHei;
  font-size: 18px;
  color: #222222;
  line-height: 24px;
  text-align: justify;
  font-style: normal;
  margin-bottom: 30px;
  position: relative;
  p {
    font-family: PingFangSC, PingFang SC;
    font-weight: 600;
    font-size: 14px;
    color: #81baab;
    font-style: normal;
    margin-right: 40px;
    cursor: pointer;
    position: absolute;
    top: 16px;
    right: 0px;
  }
}
body {
  background-color: #ffffff;
  font-size: 16px;
  color: #333333;
  font-family: "微软雅黑", "Roboto", sans-serif;
}

.page {
  width: 100%;

  .content {
    width: 100%;
    height: 500px;
    background: url() no-repeat;
    background-size: 100% 100%;
    background-position: center center;

    .sign-wrap {
      width: 100%;
      height: 100%;
    }
  }

  .con-btn {
    width: 100%;
    display: flex;
    align-content: center;
    justify-content: space-between;
    opacity: 0.75;

    span {
      font-size: 0.14rem;
      width: 100%;
      height: 0.48rem;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .staging-btn {
      color: #4154ff;
      background: #fff;
    }

    .submit-btn {
      color: #fff;
      background: #4154ff;
    }
  }
}
.content::-webkit-scrollbar {
  width: 0 !important;
}
.main1200::v-deep .el-upload__input {
  display: none;
}

.main1200::v-deep .el-upload--picture-card {
  margin: 0 15px;
  line-height: 140px;
}
.text-blue {
  cursor: pointer;
}
// .main1200::v-deep .el-form-item__content {
//   width: 944px;
// }

.layui-unselect {
  display: none;
}

#photo1 {
  width: 100%;
}

.again span {
  color: #4154ff;
  font-size: 16px;
}
.neirong .content {
  margin: 5%;
  text-align: left;
  font-size: 22px;
  line-height: 40px;
}
p {
  text-indent: 2rem;
}
.v-modal {
  z-index: 1;
}
</style>
