<template>
  <div>
    <headers></headers>
    <div class="bid" style="height: auto">
      <div class="biaoti">关于举办第三届<br>“丹青妙笔绘田园乡村”活动的通知</div>
      <div class="neirong" style="height: auto">
        <div class="content">
          <p>
            &nbsp; &nbsp; &nbsp;&nbsp;
            为深入贯彻习近平文化思想，认真落实《江苏省红色资源保护利用条例》有关要求，用好红色资源，赓续红色血脉，进一步发挥文化在推动乡村全面振兴中的引领作用，推动新时代鱼米之乡建设，在新中国成立75周年之际，省委宣传部、省住房城乡建设厅、省教育厅、团省委、省文联联合举办第三届&ldquo;丹青妙笔绘田园乡村&rdquo;活动，现将有关事项通知如下：
          </p>
          <p><strong>&nbsp; &nbsp; &nbsp;&nbsp; 一、活动主题</strong></p>
          <p>&nbsp; &nbsp; &nbsp;&nbsp; 寻红色记忆 &nbsp;绘田园新颜</p>
          <p><strong>&nbsp; &nbsp; &nbsp;&nbsp; 二、活动背景及内容</strong></p>
          <p>
            &nbsp; &nbsp; &nbsp;&nbsp;
            党的十八大以来，以习近平同志为核心的党中央高度重视红色资源利用、红色基因传承工作。2023年12月，习近平总书记在盐城市考察新四军纪念馆并发表重要讲话。总书记的重要讲话在江苏引起热烈反响，也掀起全社会对红色资源保护利用的热潮。2024年3月，《江苏省红色资源保护利用条例》正式施行，为我省红色资源的保护传承利用提供了法治保障。江苏革命历史悠久，在广袤的乡村大地上，红色资源星罗棋布、历久弥新、熠熠生辉。近年来，越来越多的艺术工作者走进乡村，寻访红色印记，传承红色基因，用艺术创作展现当代江苏乡村的红色精神、人文景观、自然风光、田园风貌，热忱描绘新时代新征程的恢弘气象。
          </p>
          <p>
            &nbsp; &nbsp; &nbsp;&nbsp;
            为集中呈现党领导下江苏乡村建设发展成就，用艺术凝固红色记忆，本次活动遴选出200余个乡村，这些乡村不仅立体展现了农业强、农村美、农民富的新时代鱼米之乡的现实模样，更拥有丰富的红色资源。期待通过本次活动，引导广大高校学子走进这些镌刻着红色印记的乡村，实地参观具有重大历史意义的红色旧址、革命历史纪念场所等，深入了解党带领人民群众英勇奋斗的光荣历史和感人事迹，让鲜活生动的革命故事、有血有肉的英雄模范、催人奋进的伟大精神见诸笔端画板、走进人民心里，进一步激发青年学子传承好、弘扬好红色基因的热情和激情，为江苏&ldquo;在建设中华民族现代文明上探索新经验&rdquo;作出积极贡献。
          </p>
          <p><strong>&nbsp; &nbsp; &nbsp;&nbsp; 三、活动要求</strong></p>
          <p><strong>&nbsp; &nbsp; （一）参赛选手</strong></p>
          <p>
            &nbsp; &nbsp; &nbsp;&nbsp;
            活动面向全省高等院校建筑、美术、设计等专业在校生（含本专科生、硕士生、博士生），欢迎其他专业有兴趣学生参与，限个人参赛，每位作者选送作品不超过两件（组画为一件），每件作品可填报一位指导教师。
          </p>
          <p><strong>&nbsp; &nbsp; （二）参赛作品</strong></p>
          <p>
            &nbsp; &nbsp; &nbsp;&nbsp;
            参赛作品须为作者在本通知发出后的原创作品，不接受其他活动获奖作品，一经发现取消参评资格。作品内容应在深度挖掘红色资源的历史意蕴与时代价值的基础上，着重表现乡村中的红色印记、红色文化，突出对美好生活的追求和对革命信仰的追寻。
          </p>
          <p><strong>&nbsp; &nbsp; （三）作品类别及尺寸要求</strong></p>
          <p>
            &nbsp; &nbsp; &nbsp;&nbsp;
            参赛作品可为中国画、油画、版画、漆画、插画、水彩粉画、素描、速写、综合材料绘画等。中国画、油画未装裱长宽尺寸不大于120
            cm，其他画类未装裱长宽尺寸不大于80 cm。
          </p>
          <p><strong>&nbsp; &nbsp; （四）活动报名</strong></p>
          <p>&nbsp; &nbsp; &nbsp;&nbsp; 1.报名截止日期：2024年10月20日。</p>
          <p>
            &nbsp; &nbsp; &nbsp;&nbsp;
            2.报名渠道：本届活动采取网络报名方式，参赛选手可登陆活动官方网站或&ldquo;丹青绘乡村&rdquo;微信小程序进行报名。报名时需填报姓名、电子邮箱、所在学校及院系专业等个人信息，并上传作品电子版、作品描绘场景同一角度实景照片、在村庄现场采风创作的佐证照片等，其中作品电子版为JPG或JPEG格式，大小为4-10
            M（分辨率不小于300dpi）。
          </p>
          <p>
            &nbsp; &nbsp; &nbsp;&nbsp;
            活动期间可关注官方网站或微信小程序，获取最新资讯。
          </p>
          <p>
            &nbsp; &nbsp; &nbsp;&nbsp; 活动官方网站：<a href="http://www.dqhxc.com"
              >http://www.dqhxc.com</a
            >
          </p>
          <p>&nbsp; &nbsp; &nbsp;&nbsp; 微信小程序二维码：</p>
          <p>
            <img
              class="wscnph"
              style="display: block; margin-left: auto; margin-right: auto"
              src="https://static.dqhxc.com/weixinerweima.png"
            />
          </p>
          <p><strong>&nbsp; &nbsp; &nbsp;&nbsp; 四、组织机构</strong></p>
          <p>
            &nbsp; &nbsp; &nbsp;&nbsp;
            1.活动设组委会，由省委宣传部、省住房城乡建设厅、省教育厅、团省委、省文联等单位组成，负责整体策划和组织实施。组委会办公室设在省住房城乡建设厅村镇建设处。
          </p>
          <p>
            &nbsp; &nbsp; &nbsp;&nbsp;
            2.活动设评委会，由组委会聘请业内知名专家、行业代表及媒体代表共同组成，负责评审标准制定、作品评审等工作。其中，评委会主任委员由江苏省美术家协会主席周京新，全国工程勘察设计大师、东南大学建筑学院教授韩冬青担任。
          </p>
          <p>
            &nbsp; &nbsp; &nbsp;&nbsp;
            3.本次活动由省美术馆、省文化馆、省建筑与历史文化研究会、省乡村规划建设研究会等为支持单位，省美术家协会为学术指导单位，荔枝新闻提供媒体支持。
          </p>
          <p><strong>&nbsp; &nbsp; &nbsp;&nbsp;五、奖励机制</strong></p>
          <p>
            &nbsp; &nbsp; &nbsp;&nbsp;
            1.活动组委会设一等奖10名、二等奖20名、三等奖30名和优秀奖若干名。对一二三等奖作者颁发证书、发放奖金，对优秀奖作者颁发证书；对获奖作品指导教师颁发&ldquo;优秀指导教师&rdquo;证书并发放奖金。以美为媒，推动获奖者与所绘乡村建立&ldquo;一对一&rdquo;长期结对机制。
          </p>
          <p>
            &nbsp; &nbsp; &nbsp;&nbsp;
            2.各院校和指导教师应将本次活动与人才培养方案和课程安排相结合，获奖作品可折算相应课程学分或成绩。学生获奖情况可纳入综合素质测评、评奖评优、研究生推免等参考。
          </p>
          <p>
            &nbsp; &nbsp; &nbsp;&nbsp;
            3.对指导学生创作的教师，学校考核教师时可折算业绩点、工作量。对指导学生取得优异成绩的教师，可以参照省级学科竞赛给予奖励。
          </p>
          <p>
            &nbsp; &nbsp; &nbsp;&nbsp;
            4.各地各级有关部门要高度重视并支持此次活动，各设区市住房和城乡建设局（委）要积极会同市委宣传部等部门，主动对接属地高校，组织选送参赛作品。
          </p>
          <p><strong>&nbsp; &nbsp; &nbsp;&nbsp; 六、活动安排</strong></p>
          <p>
            &nbsp; &nbsp; &nbsp;&nbsp;
            1.参赛者从推荐的乡村中自行选择适宜村庄进行艺术创作，按要求进行网络报名、提交作品，具体推荐乡村名单详见活动官方网站和&ldquo;丹青绘乡村&rdquo;微信小程序。
          </p>
          <p>
            &nbsp; &nbsp; &nbsp;&nbsp;
            2.活动评委会初选出入围作品并在官方网站和&ldquo;丹青绘乡村&rdquo;微信小程序接受网络投票。参赛选手须按照活动组委会有关要求，将入围作品原件（无需装裱）递送至江苏省住房和城乡建设厅科技发展中心（南京市鼓楼区草场门大街88号江苏建设大厦8楼），联系人：肖冰、张天迪，联系电话：13813897552、025-51868173、51868143。作品原件背面右下角注明作者姓名、作品名称、联系电话等信息。
          </p>
          <p>
            &nbsp; &nbsp; &nbsp;&nbsp;
            3.评委会结合网络投票情况进行复评，提出奖励等级建议，结果经组委会认定后，向社会公示。
          </p>
          <p>
            &nbsp; &nbsp; &nbsp;&nbsp;
            4.公示无异议后，向获奖作者、获奖单位及获奖指导教师颁发证书及奖金。
          </p>
          <p>
            &nbsp; &nbsp; &nbsp;&nbsp;
            5.活动组委会将适时在省美术馆向社会公开展览获奖作品，并将获奖作品汇编成册。
          </p>
          <p><strong>&nbsp; &nbsp; &nbsp;&nbsp; 七、知识产权及相关申明</strong></p>
          <p>
            &nbsp; &nbsp; &nbsp;&nbsp;
            1.作品创作不得侵害他人权益，不得违背公序良俗，发现参赛作品侵权、违背公序良俗即取消参赛资格或撤销所获奖项。
          </p>
          <p>
            &nbsp; &nbsp; &nbsp;&nbsp;
            2.所有投稿作品的署名权及相关知识产权归投稿者所有。活动结束后退还作品原件（自愿捐赠给活动组委会或接受活动组委会统一收藏的除外）。
          </p>
          <p>
            &nbsp; &nbsp; &nbsp;&nbsp;
            3.主办单位有权对所有来稿作品在各类媒体上进行宣传、出版、发行、展示、展览、研究、摄影、录像等。
          </p>
          <p>
            &nbsp; &nbsp; &nbsp;&nbsp;
            4.凡投稿者视为认同并遵守本通知的各项条款，主办单位保留对本通知各项条款的最终解释权。
          </p>
          <p>
            &nbsp; &nbsp; &nbsp;&nbsp;活动政策咨询联系人：省住房城乡建设厅村镇建设处王菁、秦振兴，025-51868597；
          </p>
          <p>&nbsp; &nbsp; &nbsp;&nbsp;报名系统技术支持联系人：杜超，13655165745。</p>
          <p>&nbsp;</p>
          <p style="text-align: right;margin-top: 100px;">中共江苏省委宣传部</p>
          <p style="text-align: right">江苏省住房和城乡建设厅</p>
          <p style="text-align: right">江苏省教育厅</p>
          <p style="text-align: right">共青团江苏省委</p>
          <p style="text-align: right">江苏省文学艺术界联合会</p>
          <p style="text-align: right">2024年5月30日</p>
        </div>
      </div>
    </div>
    <footers></footers>
  </div>
</template>
<script>
import headers from "@/components/headers.vue";
import footers from "@/components/footers.vue";

export default {
  name: "active",
  data() {
    return {
      list: {},
      titles: "",
    };
  },
  components: {
    headers,
    footers,
  },
  mounted() {
    // this.getData();
  },
  methods: {
    getData() {
      this.$axios({
        url: "notice/one",
        method: "get",
        params: {
          data: {},
        },
      }).then((res) => {
        if (res.data.code == "200") {
          if (!res.data.data || res.data.data.title == "") {
            this.titles = "活动公告暂未发布";
            this.list = "";
          } else {
            this.list = res.data.data.content;
            this.titles = res.data.data.title;
          }
        }
      });
    },
  },
};
</script>
<style>
.bid {
  background-image: url(@/assets/img/hdbg.jpg);
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-position: bottom center;
  min-width: 1200px;
  overflow: hidden;
}
.biaoti {
  /* background-image: url(@/assets/img/wenan.png); */
  width: 908px;
  height: 153px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 50px;
  margin-bottom: 0px;
  text-align: center;
  line-height: 58px;
  font-size: 36px;
  font-weight: bolder;
  color: #f49f4e;
}
.neirong {
  width: 1200px;
  /* height: 500px; */
  border-radius: 50px;
  margin-left: auto;
  background-color: #ffffff;
  margin-right: auto;
  box-shadow: 0 0 35px rgb(0 0 0 / 20%) !important;
  text-align: center;
  overflow: hidden;
  background-color: rgba(255, 255, 255, 0.8);
  margin-bottom: 50px;
}
.neirong .content {
  margin: 5%;
  text-align: left;
  font-size: 22px;
  line-height: 40px;
}
p {
  /* text-indent: 44px; */
}
</style>
