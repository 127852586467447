<template>
  <div>
     <headers></headers>
    <div id="container"></div>
    <input
      class="tab2D"
      type="button"
      id="btn-2d"
      value="切换2D"
      @click="initMap(1)"
    />
    <input
      class="tab2D"
      type="button"
      id="btn-3d"
      value="切换3D"
      @click="initMap(2)"
    />
    <footers></footers>
  </div>
   
</template>
<script>
import headers from "@/components/headers.vue";
import footers from "@/components/footers.vue";
export default {
  data() {
    return {
      property: "value",
      Longitude: this.$route.query.Longitude,
      latitude: this.$route.query.latitude,
      title: this.$route.query.title,
      val: "",
    };
  },
  components: {
    headers,
    footers,
  },
  methods: {
    initMap(e) {
      console.log(this.latitude);
      var center = new TMap.LatLng(this.Longitude, this.latitude); //******修改这里经纬度
      //初始化地图
      var map = new TMap.Map("container", {
        zoom: 16, //设置地图缩放级别
        center: center, //设置地图中心点坐标
      });
      if (e == 1) {
        map.destroy();
        var center = new TMap.LatLng(this.Longitude, this.latitude); //******修改这里经纬度
        //初始化地图
        var map = new TMap.Map("container", {
          zoom: 16, //设置地图缩放级别
          center: center, //设置地图中心点坐标
        });
        var marker = new TMap.MultiMarker({
          map: map,
          styles: {
            // 点标记样式
            marker: new TMap.MarkerStyle({
              width: 20, // 样式宽
              height: 30, // 样式高
              anchor: { x: 10, y: 30 }, // 描点位置
            }),
          },
          geometries: [
            // 点标记数据数组
            {
              // 标记位置(纬度，经度，高度)
              position: center,
              id: "marker",
            },
          ],
        });

        var label = new TMap.MultiLabel({
          id: "label-layer",
          map: map,
          styles: {
            label: new TMap.LabelStyle({
              color: "#000", // 颜色属性
              size: 30, // 文字大小属性
              offset: { x: 0, y: 30 }, // 文字偏移属性单位为像素
              angle: 0, // 文字旋转属性
              alignment: "center", // 文字水平对齐属性
              verticalAlignment: "middle", // 文字垂直对齐属性
            }),
          },
          geometries: [
            {
              id: "label", // 点图形数据的标志信息
              styleId: "label", // 样式id
              position: center, // 标注点位置
              content: this.title, // 标注文本   ******修改这里文本
              properties: {
                // 标注点的属性数据
                title: "label",
              },
            },
          ],
        });
        change2D();
      } else if (e == 2) {
        map.destroy();
        var center = new TMap.LatLng(this.Longitude, this.latitude); //******修改这里经纬度
        //初始化地图
        var map = new TMap.Map("container", {
          zoom: 16, //设置地图缩放级别
          center: center, //设置地图中心点坐标
        });
        var marker = new TMap.MultiMarker({
          map: map,
          styles: {
            // 点标记样式
            marker: new TMap.MarkerStyle({
              width: 20, // 样式宽
              height: 30, // 样式高
              anchor: { x: 10, y: 30 }, // 描点位置
            }),
          },
          geometries: [
            // 点标记数据数组
            {
              // 标记位置(纬度，经度，高度)
              position: center,
              id: "marker",
            },
          ],
        });

        var label = new TMap.MultiLabel({
          id: "label-layer",
          map: map,
          styles: {
            label: new TMap.LabelStyle({
              color: "#000", // 颜色属性
              size: 30, // 文字大小属性
              offset: { x: 0, y: 30 }, // 文字偏移属性单位为像素
              angle: 0, // 文字旋转属性
              alignment: "center", // 文字水平对齐属性
              verticalAlignment: "middle", // 文字垂直对齐属性
            }),
          },
          geometries: [
            {
              id: "label", // 点图形数据的标志信息
              styleId: "label", // 样式id
              position: center, // 标注点位置
              content: this.title, // 标注文本   ******修改这里文本
              properties: {
                // 标注点的属性数据
                title: "label",
              },
            },
          ],
        });
        change3D();
      }
      function change2D() {
        map.setViewMode("2D");
      }

      function change3D() {
        map.setViewMode("3D");
        map.setPitch(70);
      }
      // map.setViewMode(this.val);
      // map.setPitch(70);
      var marker = new TMap.MultiMarker({
        map: map,
        styles: {
          // 点标记样式
          marker: new TMap.MarkerStyle({
            width: 20, // 样式宽
            height: 30, // 样式高
            anchor: { x: 10, y: 30 }, // 描点位置
          }),
        },
        geometries: [
          // 点标记数据数组
          {
            // 标记位置(纬度，经度，高度)
            position: center,
            id: "marker",
          },
        ],
      });

      var label = new TMap.MultiLabel({
        id: "label-layer",
        map: map,
        styles: {
          label: new TMap.LabelStyle({
            color: "#000", // 颜色属性
            size: 30, // 文字大小属性
            offset: { x: 0, y: 30 }, // 文字偏移属性单位为像素
            angle: 0, // 文字旋转属性
            alignment: "center", // 文字水平对齐属性
            verticalAlignment: "middle", // 文字垂直对齐属性
          }),
        },
        geometries: [
          {
            id: "label", // 点图形数据的标志信息
            styleId: "label", // 样式id
            position: center, // 标注点位置
            content: this.title, // 标注文本   ******修改这里文本
            properties: {
              // 标注点的属性数据
              title: "label",
            },
          },
        ],
      });
    },
    // tab2D() {
    //     this.val="2D"
    //     console.log(this.val);
    // },
    // tab3D() {
    //     this.val="3D"
    //     console.log(this.val);
    // },
  },
  mounted() {
    this.initMap();
  },
};
</script>
<style type="text/css">
html,
body {
  height: 100%;
  margin: 0px;
  padding: 0px;
}

#container {
  width: 100%;
  height: 100%;
  position: relative;
}

.tab2D {
  position: absolute;
  top: 150px;
  z-index: 9999;
}

#btn-2d {
  left: 20px;
}

#btn-3d {
  left: 80px;
}
</style>
