<template>
    <div>
        <div class="hdfoot">
            <span class="padding-right"> Copyright {{ year }} www.dqhxc.com All Rights Reserved </span>
            <span class="padding-right"><a href="https://beian.miit.gov.cn/" target="_blank"><span class="text-white">
                        苏ICP备2021012425号-3</span></a> </span>
        </div>
    </div>
</template>
<script>
export default {

    name: "footers",
    data() {
        return {
            name: "",
          year: new Date().getFullYear(),
        }
    }
}
</script>
<style>
</style>
