<template>
    <el-dialog :show-close="false" :visible.sync="fileTipsObj.visible" class="file_tipe_dialog">
      <div class="file_tipe_dialog_div">
        <img src="@/assets/img/tip.jpg" alt="" />
        <p>{{ fileTipsObj.msg }}</p>
        <div @click="handleClose" class="button">
          我知道了
        </div>
      </div>
    </el-dialog>
  </template>
  
  <script>
  export default {
    props: {
        fileTipsObj: {
        type: Object,
      },
    },
    data() {
      return {};
    },
    methods: {
      // 蒙版
      handleClose() {
        this.fileTipsObj.visible = false
      },
    },
  };
  </script>
  
  <style lang="scss">
  .file_tipe_dialog {
    .file_tipe_dialog_div {
     text-align: center;
    }
    img {
      width: 102px;
      margin-top: 10px;
    }
    p {
      text-indent: 0px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 600;
      font-size: 20px;
      color: #222222;
      line-height: 28px;
      text-align: center;
      font-style: normal;
      margin-top: 20px;
    }
    .button {
    margin:0 auto;
      width: 118px;
      height: 40px;
      background: linear-gradient(90deg, #80af89 0%, #7a9f81 100%);
      border-radius: 20px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      font-size: 16px;
      color: #ffffff;
      line-height: 40px;
      text-align: center;
      font-style: normal;
      margin-top: 20px;
      cursor: pointer;
    }
  }
  .file_tipe_dialog .el-dialog {
    transform: none;
    left: 0 !important;
    position: relative !important;
    margin: 0 auto !important;
  }
  .file_tipe_dialog .el-dialog .el-dialog__body {
    overflow-y: auto !important;
    overflow-x: hidden !important;
  }
  .file_tipe_dialog .el-dialog__footer {
    width: auto !important;
  }
  .file_tipe_dialog .el-dialog {
    border-radius: 16px;
    display: flex !important;
    flex-direction: column !important;
    margin: 0 !important;
    position: absolute !important;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
    width: 240px;
    min-height: 265px;
  }
  .file_tipe_dialog .el-dialog .el-dialog__body {
    padding: 20px 20px !important;
    flex: 1;
    overflow: auto;
  }
  .file_tipe_dialog .el-dialog__header {
    // padding: 0 !important;
    padding: 0px;
  }
  .file_tipe_dialog .el-dialog__headerbtn .el-dialog__close {
    font-size: 25px !important;
    font-weight: bolder;
  }
  .file_tipe_dialog .el-dialog__title {
    font-weight: 600;
    font-size: 22px !important;
  }
  </style>
  