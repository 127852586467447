<template>
  <div>
    <headers></headers>
    <div class="bmbg">
      <div class="main1200 shadow-warp radius" style="">
        <div class="head_div">第三届“丹青妙笔绘田园乡村”活动报名表</div>

        <el-form ref="ruleForm">
          <div class="table_div">
            <table align="center" class="table_all">
              <tbody>
                <tr>
                  <th colspan="10" class="header_tr">基本信息</th>
                </tr>
                <tr>
                  <th><span>姓名</span></th>
                  <td>{{ detail.info.truename }}</td>
                  <th><span>性别</span></th>
                  <td>{{ detail.info.sexDesc }}</td>
                  <th><span>国籍</span></th>
                  <td>{{ detail.info.guoJiDesc }}</td>
                </tr>
                <tr>
                  <th><span>身份证号</span></th>
                  <td>{{ detail.info.sfzhao }}</td>
                  <th><span>所在院校</span></th>
                  <td>{{ detail.info.szyxiaoDesc }}</td>
                  <th><span>所在院系</span></th>
                  <td>{{ detail.info.szyxi }}</td>
                </tr>
                <tr>
                  <th><span>专业名称</span></th>
                  <td>{{ detail.info.zymcheng }}</td>
                  <th><span>学历</span></th>
                  <td>{{ detail.info.xueliDesc }}</td>
                  <th><span>手机号</span></th>
                  <td>{{ detail.info.phone }}</td>
                </tr>
                <tr>
                  <th><span>电子邮箱</span></th>
                  <td>{{ detail.info.email }}</td>
                  <th><span>学校所在地</span></th>
                  <td colspan="3">{{ detail.info.szcityDesc }}</td>
                </tr>
                <template v-for="(item, i) in detail.artworks">
                  <tr style="position: relative">
                    <template>
                      <th colspan="10" class="header_tr">
                        作品信息{{ detail.artworks.length > 1 ? i + 1 : "" }}
                        <div class="shing_div" v-if="item.tijiao === 2">
                          审核中
                        </div>
                        <div class="shtg_div" v-if="item.tijiao === 3">
                          审核通过
                        </div>
                        <div class="shnotg_div" v-if="item.tijiao === 4">
                          <div class="div1">审核未通过</div>
                          <div
                            class="div2"
                            @click="seeDetail(item.shenheRemark)"
                          >
                            查看详情
                          </div>
                          <div class="div2" @click="jumpToEdit(i)">
                            修改
                          </div>
                        </div>
                      </th>
                    </template>
                  </tr>
                  <tr>
                    <th><span>作品编号</span></th>
                    <td>{{ item.bmzpbh }}</td>
                    <th><span>作品名称</span></th>
                    <td>{{ item.zpmcheng }}</td>
                    <th><span>作品类型</span></th>
                    <td>{{ item.zplxingDesc }}</td>
                  </tr>
                  <tr>
                    <th><span>作品形式</span></th>
                    <td>{{ item.zpxshiDesc }}</td>
                    <th><span>创作村庄</span></th>
                    <td colspan="3">
                      {{ item.cityDesc }}{{ item.townDesc
                      }}{{ item.villageDesc }}
                    </td>
                  </tr>
                  <tr>
                    <th><span>指导老师姓名</span></th>
                    <td>{{ item.zdlshi }}</td>
                    <th><span>作品选送单位</span></th>
                    <td colspan="3">{{ item.danweiDesc }}</td>
                  </tr>
                  <tr class="tr_height">
                    <th><span>作品简介</span></th>
                    <td colspan="5" style="text-align: left">
                      {{ item.zpjjie }}
                    </td>
                  </tr>
                  <tr class="picture_tr_height">
                    <th><span>作品电子版</span></th>
                    <td colspan="5">
                      <div class="picture_td">
                        <div
                          class="picture_div"
                          @click="previewImages(item.dzban)"
                        >
                          <img
                            :src="picUrl + item.dzban"
                            style="width: 100%; height: 100%"
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr class="picture_tr_height">
                    <th><span>作品描绘场景或人物实景照片</span></th>
                    <td colspan="5">
                      <div class="picture_td">
                        <div
                          class="picture_div"
                          @click="previewImages(pic)"
                          v-for="(pic, i) in item.zpcjing.split(',')"
                        >
                          <img
                            :src="picUrl + pic"
                            style="width: 100%; height: 100%"
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr class="picture_tr_height">
                    <th><span>作者在村庄现场采风创作的佐证照片上传</span></th>
                    <td colspan="5">
                      <div class="picture_td">
                        <div
                          class="picture_div"
                          @click="previewImages(pic)"
                          v-for="(pic, i) in item.xczhua.split(',')"
                        >
                          <img
                            :src="picUrl + pic"
                            style="width: 100%; height: 100%"
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr class="picture_tr_height">
                    <th><span>指导老师指导作者创作照片</span></th>
                    <td colspan="5">
                      <div
                        class="picture_td"
                        v-if="item.lszdao != null && item.lszdao != ''"
                      >
                        <div
                          class="picture_div"
                          @click="previewImages(pic)"
                          v-for="(pic, i) in item.lszdao.split(',')"
                        >
                          <img
                            :src="picUrl + pic"
                            style="width: 100%; height: 100%"
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
        </el-form>
        <div v-if="tiJiao2" @click="jumpSubmit()" class="but_div">再次报名</div>
      </div>
    </div>

    <el-dialog
      :visible.sync="enlargementVisible"
      custom-class="centered-dialog"
      style="text-align: center"
      class="show_pic_dia"
    >
      <img :src="enlargementUrl" alt="" />
    </el-dialog>
    <showDetail :detailObj="showDetailObj" />
    <footers></footers>
  </div>
</template>
<script>
import showDetail from "@/components/showDetail";
import headers from "@/components/headers.vue";
import footers from "@/components/footers.vue";
import RequestApi from "@/api";

export default {
  data() {
    return {
      // 是否显示“再次报名”按钮
      tiJiao2: true,
      // 图片请求前缀
      picUrl: RequestApi.StaticPath,
      // 图片预览弹窗
      enlargementVisible: false,
      // 图片预览弹窗图片地址
      enlargementUrl: "",
      // 报名表数据
      detail: {
        // 基础信息
        info: {},
        // 作品信息，cishu字段：1-作品1  2-作品2
        artworks: [],
      },
      showDetailObj: {
        visible: false,
        msg: "",
      },
    };
  },
  components: {
    headers,
    footers,
    showDetail,
  },
  created() {},
  mounted() {
    this.getDetail();
  },
  methods: {
    jumpToEdit(index) {
      this.$router.push({
        path: "/src/views/modifyWork.vue",
        query: {
          zpIndex: index
        },
      });
    },
    seeDetail(remark) {
      this.showDetailObj.visible = true;
      this.showDetailObj.msg = remark;
    },
    goBaoMing() {
      this.$router.push({
        path: "/src/views/reDetails.vue",
      });
    },
    previewImages(url) {
      this.enlargementVisible = true;
      this.enlargementUrl = this.picUrl + url;
    },
    getDetail() {
      this.detail.info = {};
      this.detail.artworks = [];
      const loginUser = sessionStorage.getItem("user");
      if (!loginUser || loginUser == "") {
        this.goBaoMing();
        return;
      }
      this.$axios({
        url: "signup/apply/Detail",
        method: "get",
        params: {
          user: loginUser,
        },
      }).then((res) => {
        if (res.data.code == "200") {
          let showTable = false;
          let data = res.data.data;
          if (data && data.info) {
            if (data.info.tijiao > 1) {
              showTable = true;
            }
          }

          if (showTable) {
            if (data.info.tijiao2 > 1) {
              this.tiJiao2 = false;
            }
            this.detail.info = data.info;
            if (data.artworks && data.artworks.length > 0) {
              // for (let i = 0; i < data.artworks.length; i++) {
              //     if (data.artworks[i].ciShu === i + 1) {
              //       this.detail.artworks.push(data.artworks[i]);
              //     }
              //   }
              if (data.info.tijiao > 1) {
                this.detail.artworks.push(data.artworks[0]);
              }
              if (data.info.tijiao2 > 1) {
                this.detail.artworks.push(data.artworks[1]);
              }
            }
          } else {
            this.goBaoMing();
          }
          console.log(this.detail.artworks);
        } else {
          this.goBaoMing();
        }
      });
    },
    jumpSubmit() {
      this.$router.push({
        path: "/src/views/reDetailsTwo.vue",
      });
    },
  },
};
</script>
<style lang="scss">
.show_pic_dia .el-dialog {
  width: 600px;
  min-height: 600px;
  img {
    width: 100%;
  }
}
</style>
<style scoped lang="scss">
/* 定义对话框居中样式 */
.centered-dialog {
  display: flex;
  justify-content: center;
  align-items: center;
}

.but_div {
  width: 98px;
  height: 40px;
  background: #f0faf7;
  border-radius: 4px;
  border: 1px solid #81baab;
  font-family: PingFangSC, PingFang SC;
  font-weight: 600;
  font-size: 14px;
  color: #81baab;
  line-height: 40px;
  text-align: center;
  font-style: normal;
  margin: 20px auto 30px;
  cursor: pointer;
}
.head_div {
  font-family: PingFangSC, PingFang SC;
  font-weight: 600;
  font-size: 20px;
  color: #000000;
  line-height: 28px;
  text-align: center;
  font-style: normal;
  margin: 20px 0 30px 0;
}
.text {
  font-size: 18px;
  font-weight: bold;
  color: #78cdff;
  background: linear-gradient(180deg, #ffffff 0%, #8be2ff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 30px;
}

table {
  text-align: center;
  margin-bottom: 30px;
  border-collapse: collapse;
  /*表格样式。如果可能，边框会合并为一个单一的边框。会忽略 border-spacing 和 empty-cells 属性*/
}

.table_tit {
  font-size: 36px;
  font-weight: bold;
  color: #ffffff;
  text-align: center;
  margin-bottom: 20px;
}

.table_div {
  width: 98%;
  margin: 0 auto;
  overflow: hidden;
}

.table_all {
  border: 2px solid #375623;
  .header_tr {
    background: #c7d5c4;
  }
  .shtg_div {
    width: 88px;
    height: 30px;
    background: #81baab;
    border-radius: 8px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 600;
    font-size: 16px;
    color: #ffffff;
    line-height: 30px;
    text-align: center;
    font-style: normal;
    position: absolute;
    right: 30px;
    top: 17px;
  }
  .shing_div {
    width: 72px;
    height: 30px;
    background: #ffffff;
    border-radius: 8px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 600;
    font-size: 16px;
    color: #3e3e3e;
    line-height: 30px;
    text-align: center;
    font-style: normal;
    position: absolute;
    right: 30px;
    top: 17px;
  }
  .shnotg_div {
    display: flex;
    position: absolute;
    right: 30px;
    top: 17px;
    .div1 {
      width: 105px;
      height: 30px;
      background: #da7272;
      border-radius: 8px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 600;
      font-size: 16px;
      color: #ffffff;
      line-height: 30px;
      text-align: center;
      font-style: normal;
    }
    .div2 {
      font-family: PingFangSC, PingFang SC;
      font-weight: 600;
      font-size: 16px;
      color: #2979ff;
      line-height: 30px;
      text-align: center;
      font-style: normal;
      margin-left: 15px;
      cursor: pointer;
    }
  }

  th {
    border: 1px solid #375623;
    width: 164px;
    height: 62px;
    background: #f0f6ef;
    text-align: center;
    font-family: MicrosoftYaHei;
    font-size: 16px;
    color: #4b5849;
    font-style: normal;
    box-sizing: border-box;
    padding: 0 10px;
  }
  td {
    border: 1px solid #375623;
    width: 220px;
    height: 62px;
    text-align: center;
    box-sizing: border-box;
    padding: 0 10px;
  }
}
.picture_tr_height {
  th,
  td {
    height: 216px;
  }
}
.tr_height {
  th,
  td {
    height: 127px;
  }
}
.picture_td {
  display: flex;
  margin-left: 20px;
}
.picture_div {
  width: 146px;
  height: 146px;
  background: #f5f5f5;
  border-radius: 8px;
  border: 1px solid #b5b5b5;
  margin-right: 30px;
  cursor: pointer;
  img {
    border-radius: 8px;
  }
}
</style>
