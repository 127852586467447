<template>
  <div>
    <div class="gdhdiv flex align-center justify-center">
      <div class="dhleft">
        <div class="logo"></div>
      </div>
      <div class="dhcenter">
        <div class="dhddiv">
          <div class="dh1200">
            <ul class="flex align-center justify-around ">
              <li v-for="(item,index) in headerData" :key="index" :class="checkNavClass(item.routeName)"
                  @click="navClick(item)">
                <a>{{ item.name }}</a>
              </li>
              <router-view></router-view>
            </ul>
          </div>
        </div>

      </div>

    </div>
    <div v-show="deng" id="modal1653222159963" aria-labelledby="modalLabel" class="modal fade in"
         role="dialog" style="z-index: 2147483647; display: block; padding-right: 17px;">
      <div class="modal-dialog " style="width: 200px;">
        <div class="modal-content" style="border-radius: 20px;">
          <div class="modal-header" style="display: none;">
            <button class="close" data-dismiss="modal"
                    type="button"><span aria-hidden="true">×</span><span
                class="sr-only">Close</span></button>
            <h4 id="modalLabel" class="modal-title">提示</h4>
          </div>
          <div class="modal-body">
            <div class="text-xxl text-center margin-top"><img class="tshi" src="@/assets/img/tip.jpg"></div>
            <p class="text-xl text-center margin-top"
               style="color:#000000; text-align: center;text-indent: 0px;">{{ dialogWarnMsg }}</p>
            <div class="text-xl text-center margin-top margin-bottom">
              <button
                  class="cu-btn round  bg-gradual-green btn ok" data-dismiss="modal"
                  style="background-image: linear-gradient(45deg, #72b588, #6e9c7d);border-radius: 16px;"
                  @click="masks">我知道了
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-show="deng" class="modal-backdrop fade in"></div>

    <div v-show="dengp" class="moder_opn">
      <img class="moder_opn_close" src="@/assets/img/close.png" @click="masktp">
      <div class="moder_opn_wen">网络投票</div>
      <div class="moder_opn_om">使用微信扫一扫</div>
      <img class="moder_opn_erw" src="@/assets/img/toup.png">
      <div class="moder_dom">扫描上方二维码<span style="color:rgba(61, 61, 61, 1)">进入小程序进行投票。</span></div>
    </div>
    <div v-show="dengp" class="modal-backdrop fade in"></div>

  </div>
</template>
<script>
export default {

  name: "headers",
  data() {
    return {
      name: "",
      users: localStorage.getItem('user'),
      deng: false,
      dengp: false,
      dialogWarnMsg:"",
      enableConfig: {
        // 0:关闭 1:开启
        home: 1,
        active: 1,
        create: 1,
        signUp: 1,
        appraise: 0,
        vote: 0,
        results: 0,
        results_award: 0,
        award: 1
      },
      headerData: [{
        name: "首页",
        routeName: "home", //值需要和enableConfig对象key相对应
        routeUrl: "/src/views/index.vue"
      }, {
        name: "活动公告",
        routeName: "active",
        routeUrl: "/src/views/active.vue",
        warnMsg: "活动公告暂未发布"
      }, {
        name: "创作对象",
        routeName: "create",
        routeUrl: "/src/views/create.vue"
      }, {
        name: "点此报名",
        routeName: "signUp",
        routeUrl: "/src/views/signUp.vue",
        warnMsg: "报名暂未开始"
        //     <router-link  v-if="users==null" to="/src/views/signUp.vue" target="_blank">点此报名</router-link>
        // <router-link  v-if="users!=null" to="/src/views/reDetails.vue" target="_blank">点此报名</router-link>

    }, {
        name: "初评结果",
        routeName: "appraise",
        routeUrl: "/src/views/appraise.vue",
        warnMsg: "该功能暂未开放"
      }, {
        name: "网络投票",
        routeName: "vote",
        routeUrl: "/src/views/vote.vue",
        warnMsg: "投票暂未开放"

      }, {
        name: "复评结果",
        routeName: "results",
        routeUrl: "/src/views/results.vue",
        warnMsg: "该功能暂未开放"
      }, {
        name: "获奖名单",
        routeName: "results_award",
        routeUrl: "/src/views/results_award.vue",
        warnMsg: "该功能暂未开放"
      }, {
        name: "线上展厅",
        routeName: "award",
        routeUrl: "/src/views/award.vue"
      }]
    }
  },
  methods: {
    checkNavClass(name) {
      return {navs: this.$route.name == name}
    },
    navClick(item) {
      let status = this.enableConfig[item.routeName];
      if (status === 1) {
        if(item.name == "点此报名"){
          this.chooseNav(item)
          return
        }
        this.$router.push({path: item.routeUrl})
      }
       else if (status === 0) {
        if (item.warnMsg) {
          this.dialogWarnMsg = item.warnMsg;
          this.deng = true
        }
      }
    },
    chooseNav(item){
      const loginUser = sessionStorage.getItem("user");
      if(!loginUser || loginUser == ""){
        this.$router.push({
          path:item.routeUrl
        })
      }else{
        this.$axios({
                url: "signup/apply/Detail",
                method: "get",
                params: {
                  user: loginUser,
                },
              }).then((res) => {
                console.log(res);
                if (res.data.code == "200") {
                  let showTable = false;
                  if (res.data.data && res.data.data.info == null) {
                    sessionStorage.setItem("queryStatus", "0");
                    setTimeout(() => {
                      this.$router.push({
                        path: "/src/views/reDetails.vue",
                      });
                    }, 500);
                    return
                  }
                  if (res.data.data && res.data.data.info) {
                    if (res.data.data.info.tijiao > 1) {
                      showTable = true;
                    }
                  }

                  if (showTable) {
                    this.$router.push({
                      path: "/src/views/entryForm.vue",
                    });
                  } else {
                    if (res.data.data.info.tijiao == 1) {
                      sessionStorage.setItem("queryStatus", "1");
                      setTimeout(() => {
                        this.$router.push({
                          path: "/src/views/reDetails.vue"
                        });
                      }, 500);
                      return
                    }
                    
                  }
                } else {
                  this.msgs = res.data.msg;
                  this.deng = true;
                }
              });
      }
      
    },
    masks() {
      this.deng = false
    },
    maskt() {
      this.dengp = true
    },
    masktp() {
      this.dengp = false
    },
  },
}
</script>
<style>
.flex .navs {
  color: #fff000;
  border-bottom: #fff000 solid 4px;
}

.flex .navs a {
  color: #fff000;
  text-decoration: none;

}

.flex a {
  text-decoration: none;
}

.moder_opn {
  width: 542px;
  height: 548px;
  background: #FEFEFE;
  box-shadow: 0px 2px 13px 0px rgba(0, 0, 0, 0.32);
  position: absolute;
  top: 86px;
  left: 50%;
  margin-left: -271px;
  z-index: 10000;
}

.moder_opn_wen {
  width: 130px;
  height: 45px;
  font-size: 32px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #81BAAB;
  line-height: 45px;
  position: absolute;
  top: 20px;
  left: 206px;
}

.moder_opn_close {
  width: 22px;
  height: 22px;
  position: absolute;
  top: 27px;
  right: 29px;
  cursor: pointer;
}

.moder_opn_om {
  width: 215px;
  height: 40px;
  line-height: 40px;
  background: #EBF2F0;
  border-radius: 38px;
  font-size: 18px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #81BAAB;
  position: absolute;
  top: 89px;
  left: 50%;
  margin-left: -107.5px;
  text-align: center;
}

.moder_opn_erw {
  width: 274px;
  height: 274px;
  position: absolute;
  top: 151px;
  left: 50%;
  margin-left: -137px;
}

.moder_dom {
  width: 408px;
  height: 32px;
  font-size: 24px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #81BAAB;
  line-height: 32px;
  position: absolute;
  top: 447px;
  left: 50%;
  margin-left: -204px;
}
</style>
