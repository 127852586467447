<template>
  <div style="background-color: #f7f7f7">
    <headers></headers>
    <div v-if="enabled"  class="main1200" style="margin-bottom: 0">
      <div class="appraise_hear">
        <img src="@/assets/rw.png" alt="" />
      </div>
    </div>
    <div v-if="enabled"  class="main1230" :style="{ height: highr + 'px' }">
      <el-table
        :data="tableData"
        :header-cell-style="{ color: '#81BAAB', backgroundColor: '#EBF2F0' }"
        :style="{ height: highr + 'px' }"
        class="tabss"
        :height="highr"
        style="width: 100%"
        :cell-class-name="addClass"
      >
        <el-table-column type="indexss" align="center" label="" width="10">
        </el-table-column>
        <el-table-column type="index" align="center" label="序号" width="100">
        </el-table-column>
        <el-table-column type="indexss" align="center" label="" width="10">
        </el-table-column>
        <el-table-column
          prop="bmzpbh"
          align="center"
          label="作品编号"
          width="100"
        >
        </el-table-column>
        <el-table-column type="indexss" align="center" label="" width="50">
        </el-table-column>
        <el-table-column
          prop="trueName"
          label="作者姓名"
          width="200"
          style="text-indent: 20px"
        >
        </el-table-column>
        <el-table-column prop="yuanXiaoName" label="所在院校">
        </el-table-column>
        <el-table-column prop="zpMingCheng" label="作品名称">
        </el-table-column>
      </el-table>
      <div class="bottomb">
        根据作品编号排序，排名不分先后。如相关信息有误，请致电
        <span style="color: #81baab">025-51868960</span> 予以更正
      </div>
    </div>
    <footers></footers>
  </div>
</template>
  <script>
import headers from "@/components/headers.vue";
import footers from "@/components/footers.vue";
export default {
  name: "appraise",
  data() {
    return {
      // 是否开始
      enabled:false,
      tableData: [
        // {
        //   bmzpbh: "0002",
        //   trueName: "宗建成",
        //   yuanXiaoName: "南京大学",
        //   zpMingCheng: "石山下米糕店",
        // },
      ],
      currentPage: 1,
      highr: document.body.clientHeight - 265,
      page: 1,
      limit: 10,
      total: 0,
    };
  },
  components: {
    headers,
    footers,
  },

  mounted() {
    // this.highr = document.body.clientHeight - 265;
    this.getCpVoteVoList();
  },

  methods: {
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.limit = val;
      this.getCpVoteVoList();
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.page = val;
      this.getCpVoteVoList();
    },
    //刷新数据
    // getCpVoteVoList() {
    //   this.$axios
    //     .get(
    //       "http://47.111.169.186:8870/njdqhxc/api/vote/getFirstTrialList",
    //       {
    //         params: {
    //           page: this.page,
    //           limit:this.limit,
    //           data:{}
    //         },
    //       }
    //     )
    //     .then((res) => {
    //       console.log(res.data);
    //       if (res.data.code == "200") {
    //        this.total = res.data.data.total
    //        this.tableData = res.data.data
    //       }
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //     });
    // },
  },
};
</script>
  <style>
.bottomb {
  width: 100%;
  height: 30px;
  background: #fff;
  line-height: 30px;
  text-align: center;
}
.appraise_hear {
  margin: auto;
  height: 65px;
  border-bottom: #efefef solid 1px;
  position: relative;
}
.appraise_hear img {
  width: 257px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -15.5px;
  margin-left: -128.5px;
}
.main1230 {
  width: 1200px;
  background-color: #ffffff;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 30px;
  position: relative;
}
.tabss {
  overflow-x: hidden !important;
  overflow-y: scroll !important;
}
.tabss::-webkit-scrollbar {
  display: none;
}
.el-table__body-wrapper::-webkit-scrollbar {
  width: 0;
  background: rgba(213, 215, 220, 0.3);
  border: none;
}
.el-table__body-wrapper::-webkit-scrollbar-track {
  border: none;
}
.el-table th.gutter {
  display: none;
  width: 0;
}
.el-table colgroup col[name="gutter"] {
  display: none;
  width: 0;
}

.el-table__body {
  width: 100% !important;
}
</style>
