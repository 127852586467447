<template>
  <div style="background-color: #f7f7f7">
    <headers/>

    <div class="gallery-main flex-col" :style="{ height: highr + 'px' }" >
      <div class="grid_1 flex-row">
<!--        <div class=" main-item  main-item-vr1 flex-col" @click="goVr(1)">-->
<!--          <div class="vr-text-warpper flex-col">-->
<!--            <span class="vr-bath">首届</span>-->
<!--          </div>-->
<!--          <span class="vr-bath-title">丹青妙笔绘田园乡村线上展厅</span>-->
<!--        </div>-->
<!--        <div class="main-item main-item-vr2 flex-col" @click="goVr(2)">-->
<!--          <div class="vr-text-warpper flex-col">-->
<!--            <span class="vr-bath">第二届</span>-->
<!--          </div>-->
<!--          <span class="vr-bath-title">丹青妙笔绘田园乡村线上展厅</span>-->
<!--        </div>-->
<!--        -->

        <div class="main-item main-item-art1 flex-col"  @click="goVr(1)">
          <div class="main-item-art flex-col">
            <img src="@/assets/img/gallery/vr_banner_batch1.png"/>
          </div>
        </div>
        <div class="main-item  main-item-art2 flex-col" @click="goVr(2)">
          <div class="main-item-art flex-col">
            <img src="@/assets/img/gallery/vr_banner_batch2.png"/>
          </div>
        </div>

        <div class="main-item main-item-art1 flex-col"  @click="goGallery(1)">
          <div class="main-item-art flex-col">
            <img src="@/assets/img/gallery/art_banner_batch1.png"/>
          </div>
        </div>
        <div class="main-item  main-item-art2 flex-col" @click="goGallery(2)">
          <div class="main-item-art flex-col">
            <img src="@/assets/img/gallery/art_banner_batch2.png"/>
          </div>
        </div>
      </div>
      <div class="item-foot-wrapper flex-col">
        <span class="item-foot-text">第三届敬请期待</span>
      </div>
    </div>


<!--    <div v-show="deng" class="modal-backdrop fade in"></div>-->
    <footers></footers>
  </div>
</template>
<script>
import headers from "@/components/headers.vue";
import footers from "@/components/footers.vue";

export default {
  name: "appraise",
  data() {
    return {
      highr: document.body.clientHeight - 130,
      // vr地址，按顺序第一届，第二届。。。
      vrUrl: [
        "https://www.720yun.com/t/84vkzbrq7d9?scene_id=86396430", /* 第一届*/
        "https://www.720yun.com/t/94vk67iqg2l?scene_id=104839824" /* 第二届*/
      ],
    };
  },
  components: {
    headers,
    footers,
  },

  mounted() {
  },

  methods: {
    goVr(batch) {
      window.open(this.vrUrl[batch - 1], '_blank')
    },
    goGallery(batch) {
      window.open(this.$router.resolve({name: "galleryAward",query:{batch: batch}}).href, '_blank')

    }
  },
};
</script>
<style>
.flex-col {
  display: flex;
  flex-direction: column;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.gallery-main {
  background-color: rgba(255, 255, 254, 1);
  width: 1200px;
  margin: 0 auto;
}

.grid_1 {
  width: 1200px;
  flex-wrap: wrap;
}

.main-item {
  width: 550px;
  height: 275px;
  border-radius: 4px;
  margin: 32px 0 0 32px;
  cursor: pointer;
}

.main-item-vr1 {
  background: url('../assets/img/gallery/vr_banner_batch1.png') 100% no-repeat;
  background-size: 100% 100%;
}

.main-item-vr2 {
  border-radius: 4px;
  background-image: url('../assets/img/gallery/vr_banner_batch1.png');

}

.main-item-art1 {
  background-color: rgba(216, 216, 216, 1);
}

.main-item-art2 {
  background-color: rgba(216, 216, 216, 1);
}

.vr-text-warpper {
  background-color: rgba(235, 242, 240, 1);
  border-radius: 4px;
  height: 28px;
  width: fit-content;
  margin: 190px 0 0 40px;
}

.vr-bath {
  overflow-wrap: break-word;
  color: rgba(129, 186, 171, 1);
  font-size: 16px;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  white-space: nowrap;
  margin: 3px 10px 0 10px;
}

.vr-bath-title {
  text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  width: 234px;
  height: 25px;
  overflow-wrap: break-word;
  color: rgba(254, 254, 254, 1);
  font-size: 18px;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  text-align: left;
  white-space: nowrap;
  line-height: 25px;
  margin: 12px 0 20px 40px;
}


.main-item-art {
  height: 275px;
  width: 550px;
}

.main-item-art img {
  width: 100%;
}

.item-foot-wrapper {
  background-color: rgba(235, 242, 240, 1);
  border-radius: 6px;
  height: 48px;
  width: 550px;
  margin: 32px 0 35px 322px;
}

.item-foot-text {
  width: 126px;
  height: 25px;
  overflow-wrap: break-word;
  color: rgba(129, 186, 171, 1);
  font-size: 18px;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  text-align: right;
  white-space: nowrap;
  line-height: 26px;
  margin: 12px 0 0 233px;
}


</style>
